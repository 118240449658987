import { useTanksStore } from "../state";

export const useSelectedTank = () => {
  const selectedTankId = useTanksStore((state) => state.selectedTankId);
  const selectedTank = useTanksStore(
    (state) => state.tanks?.[state.selectedTankId || ""],
  );

  const selectTank = useTanksStore((state) => state.selectTank);

  const overview = useTanksStore(
    (state) => state.tankOverviews?.[selectedTank?.id || ""],
  );

  return { selectedTank, selectTank, overview };
};
