import React from "react";
export type NonEditingColorProps = { value: string };
export const NonEditingColor = ({ value }: NonEditingColorProps) => {
  return (
    <div className="flex flex-row">
      <div
        className="rounded-full h-full w-12 mr-2"
        style={{ backgroundColor: value }}
      >
        &nbsp;
      </div>
    </div>
  );
};
