import { Button } from "@components/form";
import { Heading } from "@components/typography";
import { useModal } from "@hooks/useModal";
import { useHistory } from "react-router-dom";
import { NewTankModal } from "../new-tank/NewTankModal";
import { TankSelector } from "./TankSelector";

export const TankSelectorPanel = () => {
  const { setModal } = useModal();
  const history = useHistory();
  const addTank = () => {
    setModal(<NewTankModal />);
  };

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex flex-row items-center justify-between p-4 border-b border-gray-300 bg-white">
        <Heading size={2} className="text-gray-700">
          Your Tanks
        </Heading>
        <Button
          color="gray"
          buttonStyle="secondary"
          className="px-2 py-1"
          onClick={() => addTank()}
        >
          New Tank
        </Button>
      </div>
      <TankSelector
        onClick={() => {
          history.push("/home");
        }}
      />
    </div>
  );
};
