import React, { ReactNode } from "react";
import cx from "classnames";
import { Tooltip } from "react-tippy";
import { MdInfoOutline } from "react-icons/md";
export type BaseChildProps = {
  error?: boolean | undefined;
  setFieldValue?: (valueKey: string, newVal: any) => void;
  id?: string;
};
export type InputWrapperProps = {
  children: [React.ReactNode, React.ReactNode];
  title: React.ReactNode | string;
  tooltip?: string;
  tooltipLink?: string;
  errorMessage?: string;
  isEditing?: boolean;
  className?: string;
};

type WrapperTitleProps = {
  children?: React.ReactNode;
  tooltip?: string;
  tooltipLink?: string;
  error?: boolean;
};

export const WrapperTitle = ({
  children: title,
  error,
  tooltip,
  tooltipLink,
}: WrapperTitleProps) => {
  return (
    <span className="flex flex-row items-center">
      <p
        className={cx(
          {
            "text-gray-700": !error,
            "text-red-700": error,
          },
          "text-lg mb-1 font-semibold",
        )}
      >
        {title}
      </p>
      {tooltip && (
        <Tooltip title={tooltip} position="top" animation="scale" theme="light">
          <MdInfoOutline
            className={cx("ml-2 mb-1", { "cursor-pointer": !!tooltipLink })}
          />
        </Tooltip>
      )}
    </span>
  );
};

export const InputWrapper = ({
  children,
  title,
  tooltip,
  tooltipLink,
  errorMessage,
  isEditing = true,
  className,
}: InputWrapperProps) => {
  return (
    <div className={cx(className, "flex flex-col")}>
      <WrapperTitle
        tooltip={tooltip}
        tooltipLink={tooltipLink}
        error={!!errorMessage}
      >
        {title}
      </WrapperTitle>
      {isEditing ? children[0] : children[1]}
      {errorMessage && (
        <p className="text-red-700 text-sm mt-1">{errorMessage.toString()}</p>
      )}
    </div>
  );
};

type NonEditingTextProps = {
  placeholder?: string;
  className?: string;
  children?: ReactNode;
  text?: string;
};

export const NonEditingText = ({
  children,
  placeholder,
  className,
  text,
}: NonEditingTextProps) => {
  return (
    <p
      className={cx(
        {
          "text-gray-500": text || children,
          "text-gray-300 italic": !text && !children && placeholder,
        },
        className,
      )}
    >
      {children || text || placeholder}
    </p>
  );
};
