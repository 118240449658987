import { useNotificationsStore } from "../../state/notifications.store";
import { Page, FrostedPanel, Heading, Button } from "@components";
import { Notification } from "@components/Notification";
import LoadingSpinner from "@components/LoadingSpinner";
import { useCallback, useMemo } from "react";

const TankNotificationsScreen = () => {
  const { notificationsObject, markNotificationRead } = useNotificationsStore(
    (state) => ({
      notificationsObject: state.notifications,
      markNotificationRead: state.markNotificationRead,
    }),
  );

  const notifications = useMemo(
    () => Object.values(notificationsObject ?? {}),
    [notificationsObject],
  );

  const markAllRead = useCallback(() => {
    notifications.forEach(({ id }) => markNotificationRead({ id }));
  }, [notifications, markNotificationRead]);

  if (!notificationsObject) return <LoadingSpinner />;

  return (
    <Page>
      <FrostedPanel ratio={1}>
        <div className="w-full h-full flex flex-col">
          <div className="text-gray-700 p-4 border-b border-gray-300 flex flex-row justify-between items-center">
            <Heading size={2}>Your Notifications</Heading>
            <Button
              type="button"
              buttonStyle="tertiary"
              color="fathomiser"
              onClick={markAllRead}
            >
              Mark all read
            </Button>
          </div>
          <div className="overflow-y-scroll h-full">
            {notifications.length > 0 && (
              <div className="pt-1">
                {notifications
                  .sort(
                    (a, b) =>
                      new Date(b.time).getTime() - new Date(a.time).getTime(),
                  )
                  .map((notification) => (
                    <div
                      key={notification.title}
                      className="flex px-4 py-2 text-md"
                    >
                      <Notification
                        title={notification.title}
                        body={notification.body}
                        time={notification.time}
                        read={notification.read === "true"}
                        className="w-64 sm:w-full md:max-w-3xl max-w-lg"
                      />
                    </div>
                  ))}
              </div>
            )}
            {notifications.length < 1 && (
              <div className="min-h-[100px] rounded-md border border-gray-300 border-dashed flex flex-col gap-y-2 py-8 justify-center items-center px-5 bg-gray-200 bg-opacity-30 m-4 ">
                <Heading className="text-center" size={2}>
                  You have no unread notifications.
                </Heading>
              </div>
            )}
          </div>{" "}
        </div>
        <div></div>
      </FrostedPanel>
    </Page>
  );
};

export default TankNotificationsScreen;
