import { Card } from "../../../../components";
import { useTanksStore } from "../../../../state";
import { GeneralTankInfoForm } from "./GeneralTankInfoForm";
import { SensorInfoForm } from "./SensorInfoForm";
import { SensorUpdatesForm } from "./SensorUpdatesForm";
import { DeleteTankForm } from "./DeleteTankForm";
import { TankVolumeForm } from "./TankVolumeForm";
import { decodeSensorId, encodeSensorId } from "@services/sensors";
import { useSelectedTank } from "@hooks/useSelectedTank";
export type TankSettingsProps = {};
export const TankSettings = () => {
  const { selectedTank } = useSelectedTank();
  const updateTank = useTanksStore((state) => state.updateTank);
  if (!selectedTank) return <></>;
  const {
    id,
    name,
    description,
    color,
    isPublic,
    shape,
    overflowDistance,
    height,
    volume,
    diameter,
    width,
    length,
    waterSource,
    imei,
    sensorModel,
    measurementInterval,
    checkInInterval,
  } = selectedTank;
  return (
    <div className="flex flex-col gap-4 px-2 mt-3 sm:px-4 pb-4 overflow-x-hidden">
      <Card className="p-4 gap-4 flex flex-col">
        <GeneralTankInfoForm
          defaultValues={{
            id,
            name,
            description,
            color,
            isPublic,
          }}
          submitText="Save"
          onSubmit={async (values) => {
            await updateTank({ tank: { ...selectedTank, ...values } });
          }}
        />
        <hr />
        <TankVolumeForm
          defaultValues={{
            shape,
            overflowDistance,
            height,
            volume,
            diameter,
            width,
            length,
            waterSource,
          }}
          submitText="save"
          onSubmit={async (values) => {
            await updateTank({ tank: { ...selectedTank, ...values } });
          }}
        />
      </Card>
      <Card className="p-4 gap-4 flex flex-col">
        <SensorInfoForm
          defaultValues={{
            id,
            sensorId: imei ? encodeSensorId(imei) : "",
            sensorModel,
          }}
          submitText="Save"
          onSubmit={async (values) => {
            const { sensorId, ...rest } = values;
            await updateTank({
              tank: {
                ...selectedTank,
                ...rest,
                imei: decodeSensorId(sensorId).imei,
              },
            });
          }}
        />
        <hr />
        <SensorUpdatesForm
          defaultValues={{
            id,
            measurementInterval,
            checkInInterval,
          }}
          submitText="Save"
          onSubmit={async (values) => {
            await updateTank({ tank: { ...selectedTank, ...values } });
          }}
        />
      </Card>
      <Card className="p-4">
        <DeleteTankForm id={id} />
      </Card>
    </div>
  );
};
