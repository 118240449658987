import { PushNotifications } from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { deleteFcmToken, registerFcmToken } from "./fcm-token";

const assertPushNotificationServices = () => {
  const isPushNotificationsAvailable =
    Capacitor.isPluginAvailable("PushNotifications");
  if (!isPushNotificationsAvailable) return false;
  const isFCMAvailable = Capacitor.isPluginAvailable("FCM");
  if (!isPushNotificationsAvailable || !isFCMAvailable) return false;
  return true;
};

let isListenerAdded = false;

const registrationListener = async ({
  value: registrationToken,
}: {
  value: string;
}) => {
  console.count("Registration Listener");
  await FCM.setAutoInit({ enabled: true });
  const { uuid: deviceId } = await Device.getId();
  const { token: fcmToken } = await FCM.getToken();

  console.log({ deviceId, fcmToken, registrationToken });

  await registerFcmToken(
    deviceId,
    fcmToken.slice(0, 2) !== "ey" ? fcmToken : registrationToken,
  );
};

const registerPushNotifications = async () => {
  console.log("registerPushNotifications");
  try {
    if (!isListenerAdded) {
      console.log("Add registration listener");
      await PushNotifications.addListener("registration", registrationListener);
      isListenerAdded = true;
    }
    await PushNotifications.register();
  } catch (error) {
    console.log({ error });
  }
};

export const initializePushNotifications = async () => {
  if (!assertPushNotificationServices()) return;
  let permStatus = await PushNotifications.checkPermissions();
  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    console.log("User denied permissions");
    return;
  }
  console.log("User accepted permissions");

  registerPushNotifications();
};

export const refreshPushNotifications = async () => {
  if (!assertPushNotificationServices()) return;
  const permStatus = await PushNotifications.checkPermissions();
  if (permStatus.receive !== "granted") return;
  registerPushNotifications();
};

export const deinitializePushNotifications = async () => {
  if (!assertPushNotificationServices()) return;
  const { uuid: deviceId } = await Device.getId();
  await deleteFcmToken(deviceId).catch((e) =>
    console.log("Deleted unregistered token", e),
  );
  await FCM.deleteInstance();
};
