import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";

type ToggleProps = {
  size?: number;
  value?: boolean;
  onClick?: (value: boolean) => void;
  id?: string;
  setFieldValue?: (valueKey: string, newVal: any) => void;
} & Omit<React.HTMLProps<HTMLDivElement>, "value" | "onClick" | "size">;

export const ToggleButton = ({
  value = false,
  size = 1,
  onClick,
  setFieldValue,
  id,
  ...props
}: ToggleProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [touched, setTouched] = useState({
    start: { x: 0, y: 0 },
    end: { x: 0, y: 0 },
    moved: false,
  });

  const handleClick = useCallback(
    (newValue: boolean) => {
      (setFieldValue && id && setFieldValue(id, newValue)) ||
        (onClick && onClick(newValue));
    },
    [setFieldValue, onClick, id],
  );

  useEffect(() => {
    const curr = ref.current;
    const startHandler = (e: TouchEvent) => {
      e.stopImmediatePropagation(); // Resolves error: [Intervention] Ignored attempt to cancel a touchend event with cancelable=false, for example because scrolling is in progress and cannot be interrupted.
      setTouched((t) => ({
        ...t,
        start: { x: e.touches[0].pageX, y: e.touches[0].pageY },
      }));
    };
    const moveHandler = (e: TouchEvent) => {
      setTouched((t) => ({
        ...t,
        end: { x: e.touches[0].pageX, y: e.touches[0].pageY },
        moved: true,
      }));
    };

    curr?.addEventListener("touchstart", startHandler);
    curr?.addEventListener("touchmove", moveHandler);
    return () => {
      curr?.removeEventListener("touchstart", startHandler);
      curr?.removeEventListener("touchmove", moveHandler);
    };
  }, [setTouched]);

  useEffect(() => {
    const curr = ref.current;
    const endHandler = (e: TouchEvent) => {
      e.preventDefault(); // Prevent click event since e.stopImmediatePropagation() doesn't affect it
      const x = touched.start.x - touched.end.x;
      const y = touched.start.y - touched.end.y;
      if (
        touched.end.x &&
        Math.abs(x) > (ref.current?.offsetWidth || 0) / 2 &&
        Math.abs(x) / 2 > Math.abs(y)
      ) {
        // Angle is no more than 45 degrees off of swipe left/right

        handleClick(!!(x < 0));
      } else if (
        !touched.moved ||
        document.elementFromPoint(touched.end.x, touched.end.y) === this
      ) {
        // Simulate "click" with a "tap"
        handleClick(!value);
      }
      // Reset
      setTouched({
        start: { x: 0, y: 0 },
        end: { x: 0, y: 0 },
        moved: false,
      });
    };
    curr?.addEventListener("touchend", endHandler);
    return () => {
      curr?.removeEventListener("touchend", endHandler);
    };
  }, [touched, setTouched, handleClick, value]);

  return (
    <div
      ref={ref}
      className={cx(
        props.className,
        "flex flex-col rounded-3xl box-content cursor-pointer transform transition-colors ease-in-out duration-300 border-4",
        value
          ? "border-fathomiser-100 bg-fathomiser-100"
          : "border-gray-300 bg-gray-300",
      )}
      style={{ width: `${size * 2.5}rem`, height: `${size}rem` }}
      onClick={() => handleClick(!value)}
      onKeyDown={(e) => e.code === "Space" && handleClick(!value)}
      tabIndex={0}
      aria-checked={value}
      role="checkbox"
    >
      <div
        className={cx(
          `w-2/5 h-full
            bg-white rounded-full shadow-md transition-all ease-in-out duration-300 transform`,
        )}
        style={{ transform: value ? "translateX(150%)" : "" }}
      ></div>
    </div>
  );
};
