import { useEffect, useMemo, useState } from "react";

import { HomeHeading } from "./HomeHeading";
import { TankSelectorPanel } from "./TankSelectorPanel";
import { useHomeTab } from "./hooks/useHomeTab";
import { FrostedPanel, Page } from "@components";
import { useTanksStore } from "state";
import { RouteChildrenProps, useHistory } from "react-router-dom";
import { useSelectedTank } from "@hooks/useSelectedTank";

type HomeProps = RouteChildrenProps<{ tankId?: string }>;

const HomeScreen = ({ match }: HomeProps) => {
  const history = useHistory();
  const tankIds = useTanksStore(({ tanks }) => {
    if (!tanks) {
      return [];
    }

    return Object.keys(tanks);
  });

  const { selectTank } = useSelectedTank();
  const { tab, resetTabs, tabSwitch, PageContent } = useHomeTab();

  const tankId = match?.params.tankId;

  const isShowingTankList = useMemo(
    () => typeof tankId === "undefined",
    [tankId],
  );

  useEffect(() => {
    if (tankIds.length === 0) {
      console.log("Tank ids len 0");
      return;
    }

    if (!tankId) {
      return;
    }

    if (!tankIds.includes(tankId)) {
      console.log("Tankids includes tankId )n't");
      history.push("/home");
      return;
    }
    console.log("Fallback");
    selectTank({ id: tankId });
  }, [tankId]);

  return (
    <Page>
      <FrostedPanel
        ratio={0.3}
        className={`sm:mb-8 sm:mt-0 gap-4 frosted-split--show-${
          isShowingTankList ? "left" : "right"
        }`}
        style={{ minHeight: 500 }}
      >
        <TankSelectorPanel />
        <div className="flex flex-col h-full">
          <div className="flex flex-col items-center md:flex-row justify-between bg-white shadow-sm z-[10]">
            <HomeHeading
              onBack={() => {
                history.push("/home");
                resetTabs();
              }}
            />
            <span className="shrink-0 py-2 pr-4">{tabSwitch}</span>
          </div>
          <div className="pb-2 md:py-0 h-full overflow-y-auto">
            <PageContent />
          </div>
        </div>
      </FrostedPanel>
    </Page>
  );
};

export default HomeScreen;
