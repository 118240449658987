import React from "react";
import cx from "classnames";
import { BaseChildProps } from "./InputWrapper";

type TextFieldProps = BaseChildProps &
  React.HTMLProps<HTMLInputElement> & {
    unit?: string;
  };

export const UnitInput = ({
  error = false,
  unit = "m",
  ...props
}: TextFieldProps) => {
  const textFieldStyles = {
    noError: "bg-white text-gray-700 placeholder-gray-300 border-gray-200",
    withError: "bg-red-100 text-red-700 placeholder-red-300 border-red-700",
  };

  return (
    <div
      className={cx(
        {
          [textFieldStyles.withError]: error,
          [textFieldStyles.noError]: !error,
        },
        "focus:outline-none p-2 rounded-md border flex-row flex justify-between",
        props.className,
      )}
    >
      <input
        type="number"
        {...props}
        className="border-none focus:outline-none bg-transparent w-full"
      />
      <p
        className={cx(
          {
            [textFieldStyles.withError]: error,
            [textFieldStyles.noError]: !error,
          },
          "pl-2",
        )}
      >
        {unit}
      </p>
    </div>
  );
};
