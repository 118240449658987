import { API } from "aws-amplify";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { apiName } from "../config";

export type Notification = {
  id: string;
  title: string;
  body: string;
  read: "true" | "false";
  time: string;
  userId: string;
  metadata: Record<string, unknown>;
};

type NotificationsState = {
  notifications?: Record<string, Notification>;
  fetchNotifications: (opts: {
    read?: "true" | "false";
    startTime?: Date;
    endTime?: Date;
  }) => Promise<Error | void>;
  markNotificationRead: (opts: { id: string }) => Promise<Error | void>;
};

export const useNotificationsStore = create<NotificationsState>()(
  devtools(
    (set, get) => ({
      notifications: undefined,
      fetchNotifications: async ({
        read,
        startTime = new Date(Date.now() - 60 * 24 * 3600 * 1000),
        endTime = new Date(),
      }) => {
        console.log("Fetching notifications");
        const path = `/notifications`;
        return API.get(apiName, path, {
          queryStringParameters: {
            ...(read ? { read } : {}),
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString(),
          },
        })
          .then((notis: Notification[]) => {
            set({
              notifications: notis.reduce(
                (acc, noti) => ({ ...acc, [noti.id]: noti }),
                {},
              ),
            });
          })
          .catch((err) => err);
      },
      markNotificationRead: async ({ id }) => {
        const path = `/notifications/${id}`;
        return API.post(apiName, path, {})
          .then(() => {
            set((state) => ({
              notifications: {
                ...state.notifications,
                [id]: { ...state.notifications?.[id]!, read: "true" },
              },
            }));

            get().fetchNotifications({});
          })
          .catch((err) => err);
      },
    }),
    {
      name: "NotificationsStore",
    },
  ),
);
