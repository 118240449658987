import * as Yup from "yup";
import { InferType } from "yup";
import { useCallback } from "react";
import { useFormSection } from "@hooks/useFormSection";
import {
  Dropdown,
  EditableFormSection,
  InputWrapper,
  NonEditingText,
  UnitInput,
} from "@components/form";

const nbsp = " ";

const tankShape: Record<string, string> = {
  circular: "Circular",
  rectangular: "Rectangular",
  custom: "Custom",
};

const waterSource: Record<string, string> = {
  rainfall: "Rainfall",
  mechanical: "Mechanical",
};

const tankShapeInputs = {
  circular: {
    inputs: ["height", "diameter"],
    calculated: "volume",
  },
  rectangular: {
    inputs: ["height", "width", "length"],
    calculated: "volume",
  },
  custom: {
    inputs: ["height", "volume"],
    calculated: undefined,
  },
};

const CUBIC_METRES_TO_LITRES_MULTIPLIER = 1000;

const calculateVolume = (values: Record<string, any>) => {
  const { shape } = values;

  if (shape === "circular") {
    const circularVolume =
      Math.PI *
      Math.pow(values.diameter / 2, 2) *
      values.height *
      CUBIC_METRES_TO_LITRES_MULTIPLIER;
    return Math.round(circularVolume);
  }

  if (shape === "rectangular") {
    const rectangularVolume =
      values.height *
      values.length *
      values.width *
      CUBIC_METRES_TO_LITRES_MULTIPLIER;
    return Math.round(rectangularVolume);
  }

  return Math.round(values.volume);
};

const tankVolumeFormSchema = Yup.object({
  shape: Yup.string()
    .oneOf(Object.keys(tankShape))
    .required("Tank shape is required"),
  waterSource: Yup.string()
    .oneOf(Object.keys(waterSource))
    .required("Water source is required"),
  volume: Yup.number()
    .required("Volume is required")
    .not([0], "Invalid volume"),
  height: Yup.number()
    .max(4, "Tank height must be less than 400cm")
    .required("Tank height is required")
    .typeError("Please enter a number")
    .not([0], "Invalid height"),
  overflowDistance: Yup.number()
    .required("Overflow Distance is required")
    .typeError("Please enter a number")
    .not([0], "Invalid overflow distance"),
  diameter: Yup.number().when("shape", {
    is: (val: string) => val === "circular",
    then: Yup.number()
      .required("Tank diameter is required")
      .typeError("Please enter a number")
      .not([0], "Invalid diameter"),
  }),
  width: Yup.number().when("shape", {
    is: (val: string) => val === "rectangular",
    then: Yup.number()
      .required("Tank width is required")
      .typeError("Please enter a number")
      .not([0], "Invalid width"),
  }),
  length: Yup.number().when("shape", {
    is: (val: string) => val === "rectangular",
    then: Yup.number()
      .required("Tank length is required")
      .typeError("Please enter a number")
      .not([0], "Invalid length"),
  }),
});

type SettingsSectionProps<T> = {
  defaultValues: Partial<T>;
  onSubmit: (newValues: T) => Promise<void>;
  onCancel?: () => void;
  defaultEditing?: boolean;
  submitText?: string;
  cancelText?: string;
};

export const TankVolumeForm = ({
  defaultValues,
  onSubmit,
  onCancel,
  defaultEditing = false,
  submitText,
  cancelText,
}: SettingsSectionProps<InferType<typeof tankVolumeFormSchema>>) => {
  const {
    getInputWrapperProps,
    getBasicFieldProps,
    isLoading,
    setIsLoading,
    isEditing,
    setIsEditing,
    submitForm,
    values,
    errors,
    resetForm,
    setFieldValue,
  } = useFormSection({
    schema: tankVolumeFormSchema,
    defaultValues,
    onSubmit,
    defaultEditing,
  });

  const getUnitInputProps = useCallback(
    (key: keyof typeof values) => {
      return {
        ...getBasicFieldProps(key),
        value: values[key] ? Math.round(values[key] * 100) : "",
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(key, parseFloat(e.currentTarget.value) / 100);
        },
      };
    },
    [setFieldValue, getBasicFieldProps, values],
  );

  return (
    <EditableFormSection
      isEditing={isEditing}
      isSaveLoading={isLoading}
      submitText={submitText}
      cancelText={cancelText}
      onEdit={() => setIsEditing(true)}
      onSave={() => {
        setIsLoading(true);
        setFieldValue("volume", calculateVolume(values)).then(() => {
          submitForm().then(() => {
            console.log("Past that");
            console.log({ errors });
            setIsLoading(false);
          });
        });
      }}
      onCancel={
        onCancel ||
        (() => {
          setIsEditing(false);
          resetForm();
        })
      }
    >
      <div className="grid grid-cols-1 gap-3 md:grid-cols-4 md:gap-6 mb-4">
        <InputWrapper
          title="Water source"
          tooltip="If you collect rainwater, use Rainfall. If you fill by a bore or other mechanical means, use Mechanical."
          className="md:col-span-2"
          {...getInputWrapperProps("waterSource")}
        >
          <Dropdown
            options={Object.keys(waterSource).map((key) => ({
              label: waterSource[key],
              value: key,
            }))}
            {...getBasicFieldProps("waterSource")}
            setFieldValue={setFieldValue}
          />
          <NonEditingText text={waterSource[values.waterSource]} />
        </InputWrapper>
        <InputWrapper
          tooltip="If you have a complicated set up such as tandem tanks or an irregular shape, use custom."
          title="Tank shape"
          className="md:col-span-2"
          {...getInputWrapperProps("shape")}
        >
          <Dropdown
            options={Object.keys(tankShape).map((key) => ({
              label: tankShape[key as keyof typeof tankShape],
              value: key,
            }))}
            {...getBasicFieldProps("shape")}
            setFieldValue={setFieldValue}
          />
          <NonEditingText text={tankShape[values.shape]} />
        </InputWrapper>
        <InputWrapper
          title="Overflow"
          tooltip="Distance between the bottom of your Fathomiser and the overflow."
          {...getInputWrapperProps("overflowDistance")}
        >
          <UnitInput unit="cm" {...getUnitInputProps("overflowDistance")} />
          <NonEditingText
            text={`${Math.round(values.overflowDistance * 100)}cm`}
          />
        </InputWrapper>
        <InputWrapper
          title="Tank Height"
          tooltip="Distance between the bottom of the tank and the overflow."
          {...getInputWrapperProps("height")}
        >
          <UnitInput unit="cm" {...getUnitInputProps("height")} />
          <NonEditingText text={`${Math.round(values.height * 100)}cm`} />
        </InputWrapper>
        {values.shape === "circular" && (
          <InputWrapper
            title="Tank Diameter"
            {...getInputWrapperProps("diameter")}
          >
            <UnitInput unit="cm" {...getUnitInputProps("diameter")} />
            <NonEditingText
              text={`${Math.round((values.diameter || 0) * 100)}cm`}
            />
          </InputWrapper>
        )}
        {values.shape === "rectangular" && (
          <>
            <InputWrapper title="Tank Width" {...getInputWrapperProps("width")}>
              <UnitInput unit="cm" {...getUnitInputProps("width")} />
              <NonEditingText
                text={`${Math.round((values.width || 0) * 100)}cm`}
              />
            </InputWrapper>
            <InputWrapper
              title="Tank Length"
              {...getInputWrapperProps("length")}
            >
              <UnitInput unit="cm" {...getUnitInputProps("length")} />
              <NonEditingText
                text={`${Math.round((values.length || 0) * 100)}cm`}
              />
            </InputWrapper>
          </>
        )}
        {values.shape === "custom" ? (
          <InputWrapper title="Tank Volume" {...getInputWrapperProps("volume")}>
            <UnitInput unit="L" {...getBasicFieldProps("volume")} />
            <NonEditingText text={`${values.volume}L`} />
          </InputWrapper>
        ) : (
          <InputWrapper title="Tank Volume" className="md:col-span-2">
            <NonEditingText>
              {isNaN(calculateVolume(values)) ? (
                <>&nbsp;</>
              ) : (
                `${calculateVolume(values)} Litres`
              )}
            </NonEditingText>
            <NonEditingText text={`${values.volume}L`} />
          </InputWrapper>
        )}
      </div>
    </EditableFormSection>
  );
};
