import React from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";

type NavButtonProps = {
  children?: React.ReactNode;
  mobile?: boolean;
  className?: string;
  onClick?: () => void;
  placeHolderName: string;
};

export const NavButton = (props: NavButtonProps) => {
  const history = useHistory();
  const location = useHistory().location.pathname;
  const nav = (path: string) => {
    history.push(path);
  };
  const {
    children,
    placeHolderName,
    mobile,
    className,
    onClick = () => nav(placeHolderName),
  } = props;
  const selected = placeHolderName === location;
  const defaultClass = mobile
    ? "flex flex-row items-center cursor-pointer"
    : "cursor-pointer h-full flex flex-row items-center px-4 rounded-md font-semibold";
  const selectedClass = mobile
    ? "bg-gray-200 active:bg-gray-300"
    : "bg-white text-white";
  const deselectedClass = mobile
    ? "active:bg-gray-200"
    : "hover:bg-fathomiser-200 text-gray-200";

  return (
    <div
      className={cx(
        defaultClass,
        selected ? selectedClass : deselectedClass,
        className,
      )}
      onClick={onClick}
      style={{ backgroundColor: selected ? "#FFFFFF4C" : "#00000000" }}
      onKeyDown={(e) => e.code === "Space" && onClick()}
      tabIndex={0}
      role="button"
    >
      {children}
    </div>
  );
};
