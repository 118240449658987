import * as Yup from "yup";

import { InferType } from "yup";
import dateFormat from "dateformat";
import { useFormSection } from "@hooks/useFormSection";

import {
  Dropdown,
  EditableFormSection,
  InputWrapper,
  NonEditingText,
} from "@components/form";
import { useTanksStore } from "../../../../state";

export const checkInIntervals = {
  "24 hours": 86400,
  "6 hours": 21600,
  "3 hours": 10800,
  "1 hour": 3600,
};

export const measurementIntervals = {
  "1 hour": 3600,
  "30 mins": 1800,
  "15 mins": 900,
  "5 mins": 300,
};

export const checkInIntervalsReverse: Record<number, string> = {
  86400: "24 hours",
  21600: "6 hours",
  10800: "3 hours",
  3600: "1 hour",
};

export const measurementIntervalsReverse: Record<number, string> = {
  3600: "1 hour",
  1800: "30 mins",
  900: "15 mins",
  300: "5 mins",
};

const sensorUpdatesFormSchema = Yup.object({
  id: Yup.string().required(),
  measurementInterval: Yup.number()
    .oneOf(
      Object.values(measurementIntervals),
      "Measurement Interval must be one of the dropdown options.",
    )
    .required("Measurement Interval is required"),
  checkInInterval: Yup.number()
    .oneOf(
      Object.values(checkInIntervals),
      "Report Interval must be one of the dropdown options.",
    )
    .required("Report Interval is required"),
});

type SettingsSectionProps<T> = {
  defaultValues: Partial<T>;
  onSubmit: (newValues: T) => Promise<void>;
  onCancel?: () => void;
  defaultEditing?: boolean;
  submitText?: string;
  cancelText?: string;
};

export const SensorUpdatesForm = ({
  defaultValues,
  onSubmit,
  onCancel,
  defaultEditing = false,
  submitText,
  cancelText,
}: SettingsSectionProps<InferType<typeof sensorUpdatesFormSchema>>) => {
  const overview = useTanksStore(
    (state) => state.tankOverviews?.[defaultValues.id || ""],
  );

  const {
    getInputWrapperProps,
    getBasicFieldProps,
    isLoading,
    setIsLoading,
    isEditing,
    setIsEditing,
    submitForm,
    values,
    resetForm,
    setFieldValue,
  } = useFormSection({
    schema: sensorUpdatesFormSchema,
    defaultValues,
    onSubmit,
    defaultEditing,
  });

  const lastCheckIn = overview?.lastActive
    ? dateFormat(new Date(overview.lastActive), "h:MMtt ddd, dS mmm")
    : undefined;

  const nextCheckIn = overview?.lastActive
    ? dateFormat(
        new Date(
          new Date(overview.lastActive).getTime() +
            values.checkInInterval * 1000,
        ),
        "h:MMtt ddd, dS mmm",
      )
    : undefined;

  return (
    <EditableFormSection
      isEditing={isEditing}
      isSaveLoading={isLoading}
      submitText={submitText}
      cancelText={cancelText}
      onEdit={() => setIsEditing(true)}
      onSave={() => {
        console.log("Saving");
        setIsLoading(true);
        submitForm().then(() => {
          setIsLoading(false);
        });
      }}
      onCancel={
        onCancel ||
        (() => {
          setIsEditing(false);
          resetForm();
        })
      }
    >
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-6">
        <InputWrapper
          title="Measurement Interval"
          tooltip="Time between measurements."
          {...getInputWrapperProps("measurementInterval")}
        >
          <Dropdown
            options={Object.keys(measurementIntervals).map((key) => ({
              label: key,
              value:
                measurementIntervals[key as keyof typeof measurementIntervals],
            }))}
            {...getBasicFieldProps("measurementInterval")}
            setFieldValue={setFieldValue}
          />
          <NonEditingText
            text={`Every ${
              measurementIntervalsReverse[values.measurementInterval]
            }`}
          />
        </InputWrapper>
        <InputWrapper
          title="Report Interval"
          tooltip="Time between reports. If your fathomiser doesn't register any change, this is when it will report to let you know everything is fine. Reducing this value will severely effect batetery life."
          {...getInputWrapperProps("checkInInterval")}
        >
          <Dropdown
            options={Object.keys(checkInIntervals).map((key) => ({
              label: key,
              value: checkInIntervals[key as keyof typeof checkInIntervals],
            }))}
            {...getBasicFieldProps("checkInInterval")}
            setFieldValue={setFieldValue}
          />
          <NonEditingText
            text={`Every ${checkInIntervalsReverse[values.checkInInterval]}`}
          />
        </InputWrapper>
        <InputWrapper title="Last Report">
          <NonEditingText text={lastCheckIn} placeholder="None" />
          <NonEditingText text={lastCheckIn} placeholder="None" />
        </InputWrapper>
        <InputWrapper title="Next Report">
          <NonEditingText text={nextCheckIn} placeholder="Unknown" />
          <NonEditingText text={nextCheckIn} placeholder="Unknown" />
        </InputWrapper>
      </div>
    </EditableFormSection>
  );
};
