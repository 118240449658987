import { useEffect, useState } from "react";
import { HiOutlineSun } from "react-icons/hi";
import { TbDroplet } from "react-icons/tb";
import { App } from "@capacitor/app";
import {
  DateRangePickerDropdown,
  Heading,
  LineChart,
  Subheading,
} from "@components";
import { useSelectedTank } from "@hooks/useSelectedTank";
import { toOnePercent } from "../../../../utils/toOnePercent";
import { useTankDataStore } from "../../../../state/tankData.store";
import { StatCardCarousel } from "./StatCardCarousel";
import { TankStatCard } from "./TankStatCard";

export const TankInfo = () => {
  const { selectedTank, overview } = useSelectedTank();
  const data = useTankDataStore((state) => state.tankData);
  const fetchTankData = useTankDataStore((state) => state.fetchTankData);
  const [month, setMonth] = useState(new Date());

  const [dateRange, setDateRange] = useState<{ from?: Date; to?: Date }>({
    from: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
    to: new Date(),
  });

  useEffect(() => {
    const listener = App.addListener("resume", () => {
      console.log("resume");
      if (!selectedTank || !dateRange.from || !dateRange.to) return;

      useTankDataStore.getState().fetchTankData({
        tankId: selectedTank?.id,
        startTime: dateRange.from,
        endTime: dateRange.to,
      });
    });
    return () => {
      listener.then((x) => x.remove());
    };
  }, [selectedTank, dateRange, setDateRange]);

  useEffect(() => {
    if (!selectedTank || !dateRange.from || !dateRange.to) return;

    fetchTankData({
      tankId: selectedTank.id,
      startTime: dateRange.from,
      endTime: dateRange.to,
    });
  }, [selectedTank, dateRange, fetchTankData]);

  if (!selectedTank) return <></>;

  const isNewTank = !overview?.lastActive;
  return (
    <div className="max-w-full flex flex-col gap-y-4 pb-4 mt-3 h-full sm:h-auto">
      {isNewTank ? (
        <div className="sm:mx-4 border border-gray-300 border-dashed rounded-md text-gray-700 p-8 h-2/3 flex flex-col justify-center items-center px-8 mx-[8px] bg-gray-200 bg-opacity-30">
          <Heading className="text-center" size={2}>
            No data has been received from this device.
          </Heading>
          <Subheading className="text-center">
            If you&apos;ve just installed it, check back after using some water,
            or in 24 hours.
          </Subheading>
        </div>
      ) : (
        <>
          <StatCardCarousel rows={2}>
            <TankStatCard
              icon={TbDroplet}
              iconClassName="text-blue-600 bg-blue-100"
              value={
                toOnePercent(overview?.currentVolume, selectedTank?.volume) +
                "%"
              }
              heading="Current Volume"
            />

            <TankStatCard
              icon={TbDroplet}
              iconClassName="text-emerald-600 bg-emerald-100"
              value={
                toOnePercent(
                  overview?.averageDailyUsage,
                  selectedTank?.volume,
                ) + "%"
              }
              heading="Average Change"
              subheading="Daily avg. (7d)"
              arrowDirection={
                (overview?.averageDailyUsage || 1) > 0 ? "up" : "down"
              }
            />

            <TankStatCard
              icon={HiOutlineSun}
              iconClassName="text-amber-600 bg-amber-100"
              value={toOnePercent(overview?.usage, selectedTank?.volume) + "%"}
              heading="Recent Change"
              subheading="24hrs"
              arrowDirection={(overview?.usage || 1) > 0 ? "up" : "down"}
            />
          </StatCardCarousel>

          <hr className="mt-2 mx-2 border-t border-gray-300" />
          <div className=" px-2 sm:px-4 w-full flex-grow flex flex-col gap-y-2 sm:px-2">
            <div className="flex flex-row justify-end">
              <DateRangePickerDropdown
                disabledDays={{ after: new Date() }}
                month={month}
                fromMonth={new Date(2000, 0, 0)}
                onMonthChange={(m) => setMonth(m)}
                onChange={(dates) => setDateRange(dates)}
                from={dateRange.from}
                to={dateRange.to}
              />
            </div>
            <LineChart data={data || []} tank={selectedTank} />
          </div>
        </>
      )}
    </div>
  );
};
