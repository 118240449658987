import * as React from "react";
import cx from "classnames";
import CountUp from "react-countup";
import LoadingSpinner from "@components/LoadingSpinner";
import { TankChart } from "../../components";
import { Tank, useTanksStore } from "../../state/tanks.store";

export type TankSelectorOptionProps = {
  tank: Pick<Tank, "id" | "volume" | "color" | "name">;
  selected: boolean;
} & React.HTMLProps<HTMLDivElement>;
export const TankSelectorOption = ({
  tank,
  selected,
  ...rest
}: TankSelectorOptionProps) => {
  const overview = useTanksStore((state) => state.tankOverviews?.[tank.id]);

  const percentage =
    tank.volume && overview?.currentVolume
      ? (overview?.currentVolume / tank.volume) * 100
      : undefined;

  const noData =
    overview !== undefined ? overview.lastActive === undefined : false;

  return (
    <div
      key={tank.id}
      className={cx(
        "rounded-md p-2 w-full flex flex-row border-gray-100 justify-between cursor-pointer relative border-2",
        {
          "sm:border-sky-700 ": selected,
        },
      )}
      {...rest}
    >
      <span className="flex flex-row gap-x-2" style={{ minHeight: "5rem" }}>
        <span className="w-16">
          <TankChart color={tank.color} percentage={percentage || 0} />
        </span>
        <span className="flex flex-col self-center">
          <h3 className="text-lg overflow-ellipsis line-clamp-1">
            {tank.name}
          </h3>
          {noData ? (
            <p className="text-l text-gray-400">No data</p>
          ) : percentage === undefined ? (
            <span className="w-6" style={{ color: tank.color }}>
              <LoadingSpinner />
            </span>
          ) : (
            <p className="text-xl font-semibold" style={{ color: tank.color }}>
              <CountUp
                start={0}
                end={parseFloat(
                  percentage.toLocaleString("en-NZ", {
                    maximumFractionDigits: 0,
                  }),
                )}
                duration={3}
              />
              %
            </p>
          )}
        </span>
      </span>
    </div>
  );
};
