import React from "react";
import Select from "react-select";
import cx from "classnames";

export type CustomOption = {
  label: React.ReactNode;
  value: any;
};

export type DropdownProps = {
  options: CustomOption[];
  value?: any;
  placeholder?: React.ReactNode;
  isSearchable?: boolean;
  setFieldValue?: (id: string, newVal: any) => void;
  onItemSelect?: (item: any) => void;
  className?: string;
  error?: boolean;
  id?: string;
};

export const Dropdown = (dropdownProps: DropdownProps) => {
  const {
    options,
    value,
    placeholder,
    setFieldValue,
    onItemSelect,
    isSearchable = false,
    error = false,
    className,
    id,
  } = dropdownProps;

  const handleSelect = (value: any) => {
    if (setFieldValue && id) {
      setFieldValue(id, value);
    } else if (onItemSelect) {
      onItemSelect(value);
    }
  };

  return (
    <Select
      menuPosition="absolute"
      menuPortalTarget={document.body}
      onChange={(obj: any) => {
        if (Array.isArray(obj)) {
          throw new Error("Received unexpected type from Select.");
        }
        handleSelect(obj?.value!);
      }}
      placeholder={placeholder}
      value={options.find((option) => option.value === value)}
      maxMenuHeight={300}
      options={options}
      isSearchable={isSearchable}
      unstyled={true}
      classNames={{
        control: (props) =>
          cx("border hover:outline-none shadow-none bg-red-500", {
            "!cursor-text": isSearchable,
            "!cursor-pointer": !isSearchable,
            "bg-red-100 border-red-700": error,
            "bg-white border-gray-200": !error,
            "rounded-b-0 rounded-t-md border-b-0": props.menuIsOpen,
            "rounded-md": !props.menuIsOpen,
          }),
        menuPortal: () => "!z-[400]",
        menuList: (props) => cx("py-0"),
        menu: (props) =>
          cx("overflow-hidden bg-white border-gray-200 border rounded-b-md"),
        valueContainer: (props) =>
          cx("p-2", {
            "border-red-700": error,
            "border-gray-200": !error,
          }),
        singleValue: (props) =>
          cx({
            "text-red-700": error,
            "text-gray-700": !error,
          }),
        indicatorSeparator: (props) => cx("hidden"),
        dropdownIndicator: (props) =>
          cx("px-2", {
            "text-red-700": error,
            "text-gray-200 hover:text-gray-700": !error,
          }),
        placeholder: (props) =>
          cx({
            "text-red-700": error,
            "test-gray-200": !error,
          }),
        input: (props) => cx("outline-none border-0 "),
        option: (props) =>
          cx(
            "p-2 !cursor-pointer",
            {
              "bg-fathomiser-100 text-white": props.isSelected,
              "hover:bg-gray-100": !props.isSelected,
            },
            "active:bg-fathomiser-200 active:text-white",
          ),
      }}
    />
  );
};
