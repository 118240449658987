import { API } from "aws-amplify";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { apiName } from "../config";
import { TankData } from "./tankData.store";
import { Tank, TankOverview } from "./tanks.store";

type PublicTankStore = {
  tank?: Tank;
  overview?: TankOverview;
  tankData?: TankData[];
  tankId?: string;
  setTank: (params: { id: string }) => Promise<void>;
  fetchData: (params: {
    startTime: Date;
    endTime: Date;
  }) => Promise<TankData[]>;
};

export const usePublicTank = create<PublicTankStore>()(
  devtools(
    (set, get) => {
      return {
        tank: undefined,
        tankData: undefined,
        tankId: undefined,
        overview: undefined,
        setTank: async ({ id }) => {
          const path = `/public-tanks/${id}`;
          const overviewPath = `${path}/overview`;
          await Promise.all([
            API.get(apiName, path, {}),
            API.get(apiName, overviewPath, {}),
          ])
            .then(([tank, overview]: [Tank, TankOverview]) => {
              set((state) => {
                return { ...state, tankId: id, tank, overview };
              });
            })
            .catch((err) => err);
        },
        fetchData: async ({ endTime, startTime }) => {
          const { tankId } = get();
          const path = `/public-tanks/${tankId}/data`;
          return API.get(apiName, path, {
            queryStringParameters: {
              startTime: startTime.toISOString(),
              endTime: endTime.toISOString(),
            },
          })
            .then((tankData: TankData[]) => {
              set({ tankData });
            })
            .catch((err) => err);
        },
      };
    },
    { name: "PublicTank" },
  ),
);
