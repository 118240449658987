export const toOnePercent = (
  numerator: number | undefined,
  demoninator: number | undefined,
) => {
  const a = numerator || 0;
  const b = demoninator || 1;

  const fraction = (a / b) * 100;

  const rounded = Math.round(fraction);

  if (Math.abs(fraction) < 1) return "<1";

  return Math.abs(rounded).toLocaleString();
};
