import axios from "axios";

/**
 * Confirms the user's email address.
 */
export const confirmUser = async (username: string, codeParameter: string) => {
  // TODO: FIX ME :skull:
  await axios
    .post(`${import.meta.env.VITE_API_URL}/authorization/confirm-user`, {
      username,
      codeParameter,
    })
    .catch((err) => console.error(JSON.stringify(err)));
};

export const verifyUserAttribute = async (
  username: string,
  codeParameter: string,
  attribute: string,
) => {
  console.log("Here");
  await axios
    .post(
      `${import.meta.env.VITE_API_URL}/authorization/verify-user-attribute`,
      {
        username,
        codeParameter,
        attribute,
      },
    )
    .catch((err) => console.error(JSON.stringify(err)));
};
