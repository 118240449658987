import React from "react";

type BreakProps = {
  className?: string;
};

export const SectionBreak = ({ className }: BreakProps) => {
  return (
    <div className={`w-full bg-gray-300 ${className}`} style={{ height: 1 }} />
  );
};
