import React from "react";
import cx from "classnames";
import LoadingSpinner from "../LoadingSpinner";

const primaryStyles: Record<string, string> = {
  black: "bg-black-500 hover:bg-black-600 active:bg-black-700 text-white ",
  white: "bg-white hover:bg-gray-50 active:bg-gray-100 text-black ",
  gray: "bg-gray-500 hover:bg-gray-600 active:bg-gray-100 text-white",
  red: "bg-red-500 hover:bg-red-600 active:bg-red-700 text-white ",
  yellow: "bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white ",
  green: "bg-green-500 hover:bg-green-600 active:bg-green-700 text-white ",
  blue: "bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white ",
  indigo: "bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700 text-white ",
  purple: "bg-purple-500 hover:bg-purple-600 active:bg-purple-700 text-white ",
  pink: "bg-pink-500 hover:bg-pink-600 active:bg-pink-700 text-white ",
  disabled: "bg-gray-200 text-gray-400",
  fathomiser:
    "bg-fathomiser-100 hover:bg-fathomiser-200 active:bg-fathomiser-300 text-white",
};

const secondaryStyles: Record<string, string> = {
  black:
    "bg-white hover:bg-gray-50 active:bg-gray-100 text-black-500 border border-black-300",
  white:
    "bg-white hover:bg-gray-50 active:bg-gray-100 text-gray-500 border border-gray-300",
  gray: "bg-white hover:bg-gray-50 active:bg-gray-100 text-gray-500 border border-gray-300",
  red: "bg-white hover:bg-gray-50 active:bg-gray-100 text-red-500 border border-red-300",
  yellow:
    "bg-white hover:bg-gray-50 active:bg-gray-100 text-yellow-500 border border-yellow-300",
  green:
    "bg-white hover:bg-gray-50 active:bg-gray-100 text-green-500 border border-green-300",
  blue: "bg-white hover:bg-gray-50 active:bg-gray-100 text-blue-500 border border-blue-300",
  indigo:
    "bg-white hover:bg-gray-50 active:bg-gray-100 text-indigo-500 border border-indigo-300 ",
  purple:
    "bg-white hover:bg-gray-50 active:bg-gray-100 text-purple-500 border border-purple-300",
  pink: "bg-white hover:bg-gray-50 active:bg-gray-100 text-pink-500 border border-pink-300",
  disabled: "bg-gray-200 text-gray-400 border border-gray-200",
  fathomiser:
    "bg-white hover:bg-gray-50 active:bg-gray-100 text-fathomiser-200 border-2 border-fathomiser-100",
};

const tertiaryStyles: Record<string, string> = {
  black: "text-black hover:text-black-600 active:text-black-700",
  white: "text-white hover:text-gray-600 active:text-gray-700",
  gray: "text-gray-500 hover:text-gray-600 active:text-gray-700",
  red: "text-red-500 hover:text-red-600 active:text-red-700",
  yellow: "text-yellow-500 hover:text-yellow-600 active:text-yellow-700",
  green: "text-green-500 hover:text-green-600 active:text-green-700",
  blue: "text-blue-500 hover:text-blue-600 active:text-blue-700",
  indigo: "text-indigo-500 hover:text-indigo-600 active:text-indigo-700",
  purple: "text-purple-500 hover:text-purple-600 active:text-purple-700",
  pink: "text-pink-500 hover:text-pink-600 active:text-pink-700",
  disabled: "bg-gray-200 text-gray-400",
  fathomiser: "text-fathomiser-200 active:text-fathomiser-300 underline",
};

export type ButtonProps = React.HTMLProps<HTMLButtonElement> & {
  loading?: boolean;
  buttonStyle?: "primary" | "secondary" | "tertiary";
  type?: "submit" | "button" | "reset" | undefined;
};

export const Button = ({
  loading = false,
  color,
  className,
  disabled = false,
  children,
  buttonStyle = "primary",
  type = "submit",
  ...rest
}: ButtonProps) => {
  const classNames: Record<string, boolean> = {
    "relative transition-opacity": true,
    "text-semibold": true, //Text
    [primaryStyles[disabled ? "disabled" : color || "fathomiser"]]:
      buttonStyle === "primary",
    [secondaryStyles[disabled ? "disabled" : color || "fathomiser"]]:
      buttonStyle === "secondary",
    [tertiaryStyles[disabled ? "disabled" : color || "fathomiser"]]:
      buttonStyle === "tertiary",
    "rounded-md focus:outline-none": true, //Other
    "cursor-default": disabled,
    "button-loading": loading,
  };
  return (
    <button
      type={type}
      className={cx(classNames, className)}
      disabled={disabled}
      {...rest}
    >
      {typeof children === "string" ? <span>{children}</span> : children}
      <span
        className="absolute left-0 right-0 top-0 bottom-0  transition-opacity"
        style={{ opacity: loading ? 1 : 0 }}
      >
        <LoadingSpinner />
      </span>
    </button>
  );
};
