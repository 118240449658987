import { API } from "aws-amplify";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { apiName } from "../config";

type User = {
  id: string;
  email: string;
  mobile?: string;
  firstName: string;
  lastName: string;
  settings: {
    units: "metric";
  };
};

type UserState = {
  user?: User;
  fetchUser: (opts: { id: string }) => Promise<Error | void>;
  updateUser: (opts: { user: User }) => Promise<Error | void>;
};

export const useUserStore = create<UserState>()(
  devtools(
    (set, get) => ({
      user: undefined,
      fetchUser: async ({ id }) => {
        console.log("Fetching user");
        const path = `/users/${id}`;
        return API.get(apiName, path, {})
          .then((fetchedUser: User) => {
            set({ user: fetchedUser });
          })
          .catch((err) => err);
      },
      updateUser: async ({ user }) => {
        console.log({ user });
        const path = `/users/${user.id}`;
        return API.patch(apiName, path, { body: user })
          .then(() => {
            set({ user });
            // the fetchUser error is supressed | TODO: fix
            get().fetchUser({ id: user.id });
          })
          .catch((err) => err);
      },
    }),
    {
      name: "UserStore",
    },
  ),
);
