import React, { Fragment } from "react";
import { TwitterPicker } from "react-color";
import Select, { components, MenuProps } from "react-select";
import cx from "classnames";
import { CustomOption } from "./Dropdown";

export type ColorPickerDropdownProps = {
  value?: string;
  colors?: string[];
  error?: boolean;
  className?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  id?: string;
  setFieldValue?: (valueKey: string, newVal: any) => void;
};

export const ColorPickerDropdown = ({
  value: color,
  colors,
  error,
  className,
  onBlur,
  id,
  setFieldValue,
}: ColorPickerDropdownProps) => {
  const defaultColors = [
    "#EF4444",
    "#F97316",
    "#F59E0B",
    "#EAB308",
    "#84CC16",
    "#22C55E",
    "#10B981",
    "#14B8A6",
    "#06B6D4",
    "#0EA5E9",
    "#3B82F6",
    "#6366F1",
    "#8B5CF6",
    "#A855F7",
    "#D946EF",
    "#EC4899",
    "#F43F5E",
  ];
  const defaultColor = defaultColors[0];

  // eslint-disable-next-line react/no-unstable-nested-components
  const Menu = (props: MenuProps<CustomOption, false>) => {
    return (
      <components.Menu {...props} className={` ${props.className}`}>
        <TwitterPicker
          className="!w-full !shadow-md !border !border-gray-200 rounded-b-md"
          color={color || defaultColor}
          triangle="hide"
          colors={colors || defaultColors}
          onChangeComplete={(color) =>
            props.selectOption({ label: "", value: color })
          }
          styles={{
            default: {
              input: { display: "none" },
              hash: { display: "none" },
              card: { boxShadow: "none", borderRadius: "none" },
            },
          }}
        />
      </components.Menu>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const Placeholder = () => {
    return (
      <Fragment>
        <div className="flex flex-row">
          <div
            className="rounded-full h-full w-12 mr-2"
            style={{ backgroundColor: color || defaultColor }}
          >
            &nbsp;
          </div>
          <p className={error ? "text-red-300" : "text-gray-300"}>Select...</p>
        </div>
      </Fragment>
    );
  };

  return (
    <Select
      id={id}
      onBlur={onBlur}
      components={{ Menu, Placeholder }}
      isSearchable={false}
      menuPortalTarget={document.body}
      value={{ label: color, value: color || defaultColor } as CustomOption}
      controlShouldRenderValue={false}
      closeMenuOnSelect={true}
      className={className}
      unstyled={true}
      onChange={(obj: any, a) => {
        if (a.action === "select-option" && id)
          setFieldValue?.(id, obj?.value.hex);
      }}
      classNames={{
        control: (props) =>
          cx(
            "border hover:outline-none shadow-none bg-red-500 !cursor-pointer",
            {
              "bg-red-100 border-red-700": error,
              "bg-white border-gray-200": !error,
              "rounded-b-0 rounded-t-md border-b-0": props.menuIsOpen,
              "rounded-md": !props.menuIsOpen,
            },
          ),
        menuPortal: () => "!z-[400]",
        valueContainer: (props) =>
          cx("p-2 !flex", {
            "border-red-700": error,
            "border-gray-200": !error,
          }),
        indicatorSeparator: (props) => cx("hidden"),
        dropdownIndicator: (props) =>
          cx("px-2", {
            "text-red-700": error,
            "text-gray-200 hover:text-gray-700": !error,
          }),
      }}
    />
  );
};
