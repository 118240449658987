import { API } from "aws-amplify";

const priceId = "price_1HTzjTFUOWiJ0xEBGD1YyA13";
const apiName = "Fathomiser";

/**
 * Creates a checkout session.
 */
export const createCheckoutSession = async (
  userId: string,
  imei: string,
  init?: any,
) => {
  const body = await API.get(apiName, "/checkout", {
    queryStringParameters: {
      priceId: priceId,
      imei,
    },
    ...init,
  }).catch((err) => console.error(JSON.stringify(err)));
  console.info({ body });
  return body!.sessionId;
};

/**
 * Creates a setup session.
 */
export const createSetupSession = async (init?: any) => {
  const body = await API.get(apiName, "/setupSession", {
    ...init,
  }).catch((err) => console.error(JSON.stringify(err)));
  console.info({ body });
  return body!.sessionId;
};

export const getPaymentDetails = async (
  init?: any,
): Promise<{ last4: string; expiryMonth: string; expiryYear: string }> => {
  console.log("Get payment details");
  const body = await API.get(apiName, "/paymentMethod", {
    ...init,
  }).catch((err) => console.error(JSON.stringify(err)));
  return body;
};
export const cancelSubscription = async (
  subscriptionId: string,
  init?: any,
): Promise<void> => {
  await API.post(apiName, "/cancelSubscription", {
    body: { subscriptionId },
    ...init,
  }).catch((err) => console.error(JSON.stringify(err)));
  return;
};
