import { base32 } from "rfc4648";

export const encodeSensorId = (imei: string) => {
  const b = new ArrayBuffer(9);
  const view = new DataView(b);
  view.setBigUint64(0, BigInt(imei));

  const sum =
    Array.from(imei).reduce((acc, char) => acc + parseInt(char), 0) % 32;
  view.setUint8(8, sum);
  const bytes = Array.from(new Uint8Array(b));
  return base32.stringify(bytes, { pad: false }).slice(2);
};

export const decodeSensorId = (sensorId: string) => {
  try {
    const uintArr = base32.parse("AA" + sensorId, { loose: true });

    const view = new DataView(uintArr.buffer);
    const imei = view.getBigUint64(0);
    const checksum = view.getUint8(8);

    return {
      imei: imei.toString(),
      checksum,
    };
  } catch (error) {
    console.log(error);
    // This is awful! Errors should not be supressed like this.
    return { imei: "0", checksum: "33" };
  }
};
