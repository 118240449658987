import * as React from "react";
import cx from "classnames";

export type SwitchOption<T = any> = {
  label: React.ReactNode;
  value: T;
};
export type SwitchProps<T = any> = {
  options: SwitchOption<T>[];
  value: T;
  onSelect?: (option: SwitchOption<T>) => void;
  className?: string;
};
export const Switch = ({
  options,
  value,
  onSelect,
  className,
}: SwitchProps) => {
  return (
    <div
      className={cx(
        "rounded-lg grid",
        className,
      )}
      style={{
        gridTemplateColumns: `repeat(${options.length}, minmax(0, 1fr))`,
      }}
    >
      {options.map((o) => (
        <button
          key={o.value}
          onClick={() => onSelect && onSelect(o)}
          className={`transition-colors px-2 rounded-md flex flex-row z-10 justify-center items-center cursor-pointer ${
            o.value === value.value ? " text-fathomiser-100" : ""
          }`}
        >
          {o.label}
        </button>
      ))}
    </div>
  );
};
