import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { InferType } from "yup";
import { useFormSection } from "@hooks/useFormSection";

import { useModal } from "@hooks/useModal";
import {
  EditableFormSection,
  InputWrapper,
  NonEditingText,
  PhoneInput,
  TextField,
} from "@components/form";

const generalAccountInfoFormSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Email address not valid")
    .required("Email is required"),
  mobile: Yup.string()
    .transform((val) => {
      console.log({ val });
      return val;
    })
    .matches(/^\+([0-9]*) ([0-9]*)$/g, "Phone number is invalid")
    .optional(),
});

type SettingsSectionProps<T> = {
  defaultValues: T;
  onSubmit: (newValues: T) => Promise<void>;
};

export const GeneralAccountInfoForm = ({
  defaultValues,
  onSubmit,
}: SettingsSectionProps<InferType<typeof generalAccountInfoFormSchema>>) => {
  const history = useHistory();
  const { createQuestion, clearModal } = useModal();

  const {
    getInputWrapperProps,
    getBasicFieldProps,
    isLoading,
    setIsLoading,
    isEditing,
    setIsEditing,
    submitForm,
    values,
    resetForm,
    setFieldValue,
  } = useFormSection({
    schema: generalAccountInfoFormSchema,
    defaultValues,
    onSubmit,
  });

  return (
    <EditableFormSection
      isEditing={isEditing}
      isSaveLoading={isLoading}
      onEdit={() => setIsEditing(true)}
      onSave={() => {
        setIsLoading(true);
        if (values.email === defaultValues.email) {
          submitForm().then(() => {
            setIsLoading(false);
          });
          return;
        }
        return createQuestion((res, rej) => ({
          title: "Are you sure you want to change your email?",
          body: "Changing your email address will sign you out. You will not be able to sign in again until you verify your new email address.",
          actions: [
            {
              color: "gray",
              children: "Cancel",
              buttonStyle: "secondary",
              key: "cancel",
              onClick: () => {
                clearModal();
                setIsLoading(false);
                res();
              },
            },
            {
              color: "fathomiser",
              children: "Update",
              buttonStyle: "primary",
              key: "update",
              onClick: () => {
                res();
                clearModal();
                submitForm().then(() => {
                  setIsLoading(false);
                });
              },
            },
          ],
        }));
      }}
      onCancel={() => {
        setIsEditing(false);
        resetForm();
      }}
    >
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-6">
        <InputWrapper title="First Name" {...getInputWrapperProps("firstName")}>
          <TextField type="text" {...getBasicFieldProps("firstName")} />
          <NonEditingText text={values.firstName} />
        </InputWrapper>
        <InputWrapper title="Last Name" {...getInputWrapperProps("firstName")}>
          <TextField type="text" {...getBasicFieldProps("lastName")} />
          <NonEditingText text={values.lastName} />
        </InputWrapper>
        <InputWrapper
          className="col-span-1 md:col-span-2"
          title="Email"
          {...getInputWrapperProps("email")}
        >
          <TextField
            className="w-full"
            type="email"
            {...getBasicFieldProps("email")}
          />
          <NonEditingText text={values.email} />
        </InputWrapper>
        <InputWrapper title="Mobile" {...getInputWrapperProps("mobile")}>
          <PhoneInput
            className="w-full"
            type="text"
            placeholder="021 123 4567"
            {...getBasicFieldProps("mobile")}
            setFieldValue={setFieldValue}
          />
          <NonEditingText text={values.mobile} placeholder="None" />
        </InputWrapper>
      </div>
    </EditableFormSection>
  );
};
