import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormSection } from "@hooks/useFormSection";
import { Button, InputWrapper, TextField } from "../../components";

enum ResetPasswordStage {
  CODE = "code",
  NEW_PASSWORD = "new_password",
}

const formSchema = Yup.object({
  email: Yup.string()
    .email("Email address not valid")
    .required("Email is required"),
  stage: Yup.string().oneOf(["code", "new_password"]),
  code: Yup.string().when("stage", {
    is: "new_password",
    then: Yup.string()
      .matches(/\d.*/g, "Must be all numbers")
      .required("Code is required"),
  }),
  password: Yup.string().when("stage", {
    is: "new_password",
    then: Yup.string().required("Password is required"),
  }),
  confirmPassword: Yup.string().when("stage", {
    is: "new_password",
    then: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .required("Please confirm your password"),
  }),
});

type FormSchema = Yup.InferType<typeof formSchema>;

export const ResetPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [stage, setStage] = useState<ResetPasswordStage>(
    ResetPasswordStage.CODE,
  );

  const sendCode = async (values: FormSchema) => {
    setLoading(true);
    try {
      await Auth.forgotPassword(values.email);
      setStage(ResetPasswordStage.NEW_PASSWORD);
    } catch (e: any) {
      console.error(e);
    }
    setLoading(false);
  };

  const changePassword = async (values: FormSchema) => {
    setLoading(true);
    if (!values.code) {
      console.error("No code provided to change password!");
      return;
    }
    if (!values.password) {
      console.error("No password provided to change password!");
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(
        values.email,
        values.code,
        values.password,
      );
      history.push("/");
    } catch (e: any) {
      console.error(e);
    }
    setLoading(false);
  };

  const submit = async (values: FormSchema) => {
    if (!values.code) {
      await sendCode(values);
    } else {
      await changePassword(values);
    }
  };

  const {
    setFieldTouched,
    getBasicFieldProps,
    getInputWrapperProps,
    setFieldValue,
    handleSubmit,
    values,
  } = useFormSection({
    defaultValues: {
      email: "",
      stage: ResetPasswordStage.CODE,
      code: "",
      password: "",
      confirmPassword: "",
    },
    schema: formSchema,
    onSubmit: submit,
  });

  useEffect(() => {
    setFieldValue("stage", stage, false);

    // Submit sets all fields touched -- Need to undo it for second submit
    setFieldTouched("code", false);
    setFieldTouched("password", false);
    setFieldTouched("confirmPassword", false);
  }, [stage, setFieldValue, setFieldTouched]);

  return (
    <div className="flex flex-col w-full">
      {stage == ResetPasswordStage.CODE ? (
        <>
          <p className="font-light mb-4">
            Enter your email address below and we&apos;ll send you a code to
            reset your password.
          </p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <InputWrapper title="Email">
              <TextField
                className="w-full"
                type="email"
                placeholder="example@email.com"
                {...getBasicFieldProps("email")}
              />
              <></>
            </InputWrapper>
            <Button loading={loading} className="px-4 py-2 w-full">
              Send code
            </Button>
          </form>
          <div className="w-full flex justify-between text-sm mt-4">
            <div className="flex text-gray-600">
              <p>Already know your password?&nbsp;</p>
              <a href="/login">
                <button className="self-center cursor-pointer underline">
                  Login
                </button>
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <p className="font-light mb-4">
            We&apos;ve sent a code to the the email you provided, {values.email}
            .
          </p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <InputWrapper title="Code" {...getInputWrapperProps("code")}>
              <></>
              <TextField
                className="w-full"
                type="text"
                placeholder="123456"
                {...getBasicFieldProps("code")}
              />
            </InputWrapper>
            <InputWrapper
              title="New Password"
              {...getInputWrapperProps("password")}
            >
              <></>
              <TextField
                className="w-full"
                type="password"
                placeholder="********"
                {...getBasicFieldProps("password")}
              />
            </InputWrapper>
            <InputWrapper
              title="Confirm New Password"
              {...getInputWrapperProps("confirmPassword")}
            >
              <></>
              <TextField
                className="w-full"
                type="password"
                placeholder="********"
                {...getBasicFieldProps("confirmPassword")}
              />
            </InputWrapper>
            <Button loading={loading} className="px-4 py-2 w-full">
              Reset Password
            </Button>
          </form>
        </>
      )}
    </div>
  );
};
