import { NewTankModal } from "./NewTankModal";

export const NewTank = () => {
  return (
    <NewTankModal className="w-full h-full bg-gray-50 px-4 lg:px-1/4">
      <div className="flex flex-row justify-center mt-16">
        <img
          src="/images/Fathomiser-TEXT.png"
          alt="logo"
          style={{ maxHeight: 80 }}
        />
      </div>
    </NewTankModal>
  );
};
