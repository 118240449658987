import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { Button, InputWrapper } from "@components/form";
import { createSetupSession } from "@services/subscriptions";
import LoadingSpinner from "@components/LoadingSpinner";
import { StripeConfig } from "../../config";

type PaymentCard = {
  last4?: string;
  expiryMonth?: string | number;
  expiryYear?: string | number;
};

const CardNumber = ({ card }: { card?: PaymentCard }) => {
  return card ? (
    <>
      <p className="text-gray-500 mr-4">{`**** **** **** ${card.last4}`}</p>
      <p className="text-gray-500">
        {String(card.expiryMonth).padStart(2, "0")}/{card.expiryYear}
      </p>
    </>
  ) : (
    <>No payment method.</>
  );
};

export type CardDetailsProps = {};
export const CardDetails = ({}: CardDetailsProps) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [card, setCard] = useState<PaymentCard | undefined>();
  const [loading, setLoading] = useState(false);

  // TODO: Reimplement this feature
  // useEffect(() => {
  //   getPaymentDetails()
  //     .then((card) => {
  //       if (card) setCard(card);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, []);

  const button = (
    <Button
      buttonStyle="secondary"
      color="gray"
      className="px-2 py-1 mt-3 md:mt-0"
      loading={buttonLoading}
      onClick={() => {
        setButtonLoading(true);
        loadStripe(StripeConfig.publishableKey).then(async (stripe) => {
          const sessionId = await createSetupSession();
          await stripe?.redirectToCheckout({ sessionId });
        });
      }}
    >
      {card ? <p>Change Payment Method</p> : <p>Add Payment Method</p>}
    </Button>
  );

  return (
    <div>
      {/* isEditing is used to select the loading spinner. This is bad */}
      <InputWrapper title="Card Number" isEditing={loading}>
        <LoadingSpinner />
        <div className="w-full flex flex-col md:flex-row md:justify-between md:items-center">
          <div className="flex flex-row flex-wrap text-gray-700">
            <CardNumber card={card} />
          </div>
          {button}
        </div>
      </InputWrapper>
    </div>
  );
};
