import * as Yup from "yup";

import { InferType } from "yup";
import { useFormSection } from "@hooks/useFormSection";

import {
  EditableFormSection,
  InputWrapper,
  NonEditingText,
  TextField,
} from "@components/form";
import { decodeSensorId } from "@services/sensors";

const sensorInfoFormSchema = Yup.object({
  id: Yup.string().required(),
  sensorId: Yup.string()
    .required("Sensor ID is required")
    .matches(
      /^[A-Za-z2-7]*$/,
      "Sensor ID only includes letters and numbers 2 through 7.",
    )
    .test({
      name: "sensor-id-checksum",
      message: "Invalid sensor ID. Please try again.",
      test: (value) => {
        if (!value) return false;
        const { imei, checksum } = decodeSensorId(value);
        const sum = Array.from(imei).reduce(
          (acc, char) => acc + parseInt(char),
          0,
        );
        return sum % 32 === checksum;
      },
    }),
  sensorModel: Yup.string(),
});

const modelMap: Record<string, string> = {
  "origin-v1": "Origin",
};

type SettingsSectionProps<T> = {
  defaultValues: Partial<T>;
  onSubmit: (newValues: T) => Promise<void>;
  onCancel?: () => void;
  submitText?: string;
  defaultEditing?: boolean;
  cancelText?: string;
};

export const SensorInfoForm = ({
  defaultValues,
  onSubmit,
  onCancel,
  defaultEditing = false,
  submitText,
  cancelText,
}: SettingsSectionProps<InferType<typeof sensorInfoFormSchema>>) => {
  const {
    getInputWrapperProps,
    getBasicFieldProps,
    isLoading,
    setIsLoading,
    isEditing,
    setIsEditing,
    submitForm,
    values,
    resetForm,
  } = useFormSection({
    schema: sensorInfoFormSchema,
    defaultValues: {
      id: defaultValues.id || "",
      sensorId: defaultValues.sensorId || "",
      sensorModel: defaultValues.sensorModel || "origin-v1",
    },
    onSubmit,
    defaultEditing,
  });

  return (
    <EditableFormSection
      isEditing={isEditing}
      isSaveLoading={isLoading}
      submitText={submitText}
      cancelText={cancelText}
      onEdit={() => setIsEditing(true)}
      onSave={() => {
        console.log("Saving");
        setIsLoading(true);
        submitForm().then(() => {
          setIsLoading(false);
        });
      }}
      onCancel={
        onCancel ||
        (() => {
          setIsEditing(false);
          resetForm();
        })
      }
    >
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-6 w-full">
        <InputWrapper
          title="Sensor ID"
          {...getInputWrapperProps("sensorId")}
          tooltip="The short code on the device. E.g. AABBCCDDEEFFGGHH"
        >
          <TextField {...getBasicFieldProps("sensorId")} />
          <NonEditingText text={values.sensorId.toUpperCase()} />
        </InputWrapper>

        <InputWrapper
          title="Sensor Model"
          {...getInputWrapperProps("sensorModel")}
        >
          <NonEditingText
            text={modelMap[values.sensorModel || ""]}
            placeholder="No description"
          />
          <NonEditingText
            text={modelMap[values.sensorModel || ""]}
            placeholder="No description"
          />
        </InputWrapper>
      </div>
    </EditableFormSection>
  );
};
