import React from "react";
import ReactDOM from "react-dom";
import { Card } from "./Card";

export type ModalProps = {
  overlayProps?: React.HTMLProps<HTMLDivElement>;
} & React.HTMLProps<HTMLDivElement>;

export const Modal = ({ overlayProps, children, ...props }: ModalProps) => {
  return ReactDOM.createPortal(
    <div
      className="w-screen h-screen absolute top-0 left-0 z-50 flex flex-row items-center justify-center"
      style={{ backgroundColor: "#00000088" }}
      {...overlayProps}
    >
      <Card {...props}>{children}</Card>
    </div>,
    document.body,
  );
};
