//Information about the modal

import React, { useState, createContext, useContext } from "react";

type ModalContext = {
  modal: JSX.Element | undefined;
  setModal: (modal: JSX.Element | undefined) => void;
};

const initial = {
  modal: undefined,
  setModal: () => {},
};

const useModal = () => {
  const [modalState, setModalState] = useState<ModalContext["modal"]>(
    initial.modal,
  );
  const setModal = (modal: JSX.Element | undefined) => {
    setModalState(() => modal);
  };

  return {
    modal: modalState,
    setModal,
  };
};

export const modalContext = createContext<ModalContext>(initial);

const Provider = ({ children }: { children: React.ReactNode }) => {
  return (
    <modalContext.Provider value={useModal()}>{children}</modalContext.Provider>
  );
};

const ChildRenderer = ({ children }: { children: React.ReactNode }) => {
  const { modal } = useContext(modalContext);
  return (
    <>
      {modal}
      {children}
    </>
  );
};

export const ModalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Provider>
      <ChildRenderer>{children}</ChildRenderer>
    </Provider>
  );
};
