import { useState } from "react";
import { HiOutlineClipboard } from "react-icons/hi";
import { BaseChildProps, Button } from "@components/form";
import { ToggleButton } from "@components/form/Toggle";
import { Tooltip } from "react-tippy";

type Props = {
  value?: boolean;
} & BaseChildProps;

export const ShareableLinkToggle = ({
  value = false,
  id,
  setFieldValue,
}: Props) => {
  return (
    <div className="flex flex-row gap-2">
      <ToggleButton
        id={id}
        value={value}
        setFieldValue={setFieldValue}
        className="flex flex-shrink-0 "
      />
      <p>{value ? "Public" : "Not public"}</p>
    </div>
  );
};

export const ShareableLink = ({ link }: { link: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(link);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <div className="flex flex-row relative items-center justify-between">
      <a
        className="text-sky-500 underline decoration-teal-500 underline-offset-2 hover:text-sky-700 flex-shrink max-w-[90%] break-anywhere"
        href={`https://${link}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {link}
      </a>
      <Tooltip
        open={isCopied}
        title="Copied to Clipboard"
        position="top"
        trigger="manual"
        animation="scale"
        theme="light"
      >
        <Button
          onClick={handleCopy}
          className="p-1"
          color="gray"
          buttonStyle="secondary"
        >
          <HiOutlineClipboard size="1.4rem" />
        </Button>
      </Tooltip>
    </div>
  );
};
