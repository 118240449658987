import { API } from "aws-amplify";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { apiName } from "../config";

export type TankData = {
  tankId: string;
  time: string;
  data: number;
};

type TankDataState = {
  tankData?: TankData[];
  fetchTankData: (opts: {
    tankId: string;
    startTime: Date;
    endTime: Date;
  }) => Promise<Error | void>;
};

export const useTankDataStore = create<TankDataState>()(
  devtools(
    (set, get) => ({
      tankData: undefined,
      fetchTankData: async ({ tankId, startTime, endTime }) => {
        console.log("Fetching tank data");
        const path = `/tanks/${tankId}/data`;
        return API.get(apiName, path, {
          queryStringParameters: {
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString(),
          },
        })
          .then((tankData: TankData[]) => {
            set({ tankData });
          })
          .catch((err) => err);
      },
    }),
    {
      name: "TankDataStore",
    },
  ),
);
