import * as Yup from "yup";

import { InferType } from "yup";
import { useFormSection } from "@hooks/useFormSection";
import {
  ColorPickerDropdown,
  EditableFormSection,
  InputWrapper,
  NonEditingText,
  TextArea,
  TextField,
} from "@components/form";
import { NonEditingColor } from "./components/NonEditingColor";
import {
  ShareableLink,
  ShareableLinkToggle,
} from "./components/ShareableLinkToggle";

const generalTankInfoFormSchema = Yup.object({
  id: Yup.string().required(),
  name: Yup.string().required("Tank name is required"),
  color: Yup.string().required("Colour is required"),
  description: Yup.string().optional(),
  isPublic: Yup.boolean().required("Tank sharing setting is required"),
});

export type SettingsSectionProps<T> = {
  defaultValues: Partial<T>;
  onSubmit: (newValues: T) => Promise<void>;
  onCancel?: () => void;
  defaultEditing?: boolean;
  submitText?: string;
  cancelText?: string;
};

export const GeneralTankInfoForm = ({
  defaultValues,
  onSubmit,
  onCancel,
  defaultEditing = false,
  submitText,
  cancelText,
}: SettingsSectionProps<InferType<typeof generalTankInfoFormSchema>>) => {
  const {
    getInputWrapperProps,
    getBasicFieldProps,
    isLoading,
    setIsLoading,
    isEditing,
    setIsEditing,
    submitForm,
    values,
    resetForm,
    setFieldValue,
  } = useFormSection({
    schema: generalTankInfoFormSchema,
    defaultValues: {
      id: defaultValues.id || "",
      name: defaultValues.name || "",
      color: defaultValues.color || "#EF4444",
      description: defaultValues.description || "",
      isPublic: defaultValues.isPublic ?? false,
    },
    onSubmit,
    defaultEditing,
  });

  return (
    <EditableFormSection
      isEditing={isEditing}
      isSaveLoading={isLoading}
      submitText={submitText}
      cancelText={cancelText}
      onEdit={() => setIsEditing(true)}
      onSave={() => {
        console.log("Saving");
        setIsLoading(true);
        submitForm().then(() => {
          setIsLoading(false);
        });
      }}
      onCancel={
        onCancel ||
        (() => {
          setIsEditing(false);
          resetForm();
        })
      }
    >
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-6">
        <InputWrapper
          title="Name"
          {...getInputWrapperProps("name")}
          tooltip="A name to help identify the tank."
          className="col-span-2 "
        >
          <TextField placeholder="Tank name" {...getBasicFieldProps("name")} />
          <NonEditingText text={values.name} />
        </InputWrapper>
        <InputWrapper
          title="Colour"
          isEditing={isEditing}
          tooltip="A colour to help identify the tank."
          className="col-span-2"
        >
          <ColorPickerDropdown
            className="w-full"
            {...getBasicFieldProps("color")}
            setFieldValue={setFieldValue}
          />
          <NonEditingColor value={values.color} />
        </InputWrapper>
        <InputWrapper
          title="Description"
          {...getInputWrapperProps("description")}
          tooltip="A short description of the tank."
          className="col-span-2"
        >
          <TextArea
            placeholder="A short description of your tank."
            {...getBasicFieldProps("description")}
          />
          <NonEditingText
            text={values.description}
            placeholder="No description"
          />
        </InputWrapper>
        <InputWrapper
          title="Tank Dashboard Sharing Link"
          {...getInputWrapperProps("isPublic")}
          tooltip="Enable a public sharing link where you can share the amount of water in your tank. Shared users will not be able to change settings. You can change this setting later."
          className="col-span-2"
        >
          <ShareableLinkToggle
            {...getBasicFieldProps("isPublic")}
            setFieldValue={setFieldValue}
          />
          {values.isPublic ? (
            <ShareableLink
              link={`${import.meta.env.VITE_APP_URL}/shared/${values.id}`}
            />
          ) : (
            <NonEditingText placeholder="Not public" />
          )}
        </InputWrapper>
      </div>
    </EditableFormSection>
  );
};
