import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { HiOutlineLogout } from "react-icons/hi";
import { useState } from "react";
import { useUserStore } from "../../state";
import { SubscriptionForm } from "./SubscriptionForm";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { GeneralAccountInfoForm } from "./GeneralAccountInfoForm";
import { CardDetails } from "./CardDetails";
import LoadingSpinner from "@components/LoadingSpinner";
import {
  Page,
  FrostedPanel,
  Heading,
  Card,
  SectionBreak,
  Button,
} from "@components";
import { deinitializePushNotifications } from "@services/push-notifications";
import { Capacitor } from "@capacitor/core";
import { usePlatform } from "@hooks/usePlatform";

const Account = () => {
  const history = useHistory();
  const user = useUserStore((state) => state.user);
  const updateUser = useUserStore((state) => state.updateUser);

  const [logoutLoading, setLogoutLoading] = useState(false);

  const signOut = async () => {
    setLogoutLoading(true);
    await deinitializePushNotifications();
    await Auth.signOut();
    history.push("/");
  };

  const platform = usePlatform();

  return (
    <Page>
      <FrostedPanel>
        <div className="p-4 md:p-6">
          <div className="flex flex-row items-center pb-4 justify-between ">
            <Heading size={2} className="text-gray-700">
              My Account
            </Heading>
            <Button
              className="sm:hidden flex cursor-pointer whitespace-nowrap flex-row items-center py-1 px-2"
              loading={logoutLoading}
              onClick={signOut}
              buttonStyle="secondary"
              color="red"
            >
              <HiOutlineLogout
                size="1.5rem"
                className="mr-2 transform rotate-180"
              />
              <span>Logout</span>
            </Button>
          </div>
          <div className="flex flex-col md:flex-row gap-3">
            <Card className="p-4 md:p-6 w-full">
              {user ? (
                <GeneralAccountInfoForm
                  defaultValues={{
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    mobile: user.mobile,
                  }}
                  onSubmit={async (newValues) => {
                    await updateUser({ user: { ...user, ...newValues } });
                  }}
                />
              ) : (
                <LoadingSpinner />
              )}
              <SectionBreak className="my-6" />
              <ChangePasswordForm />
            </Card>
            {platform === "web" ? (
              <Card className="p-4 md:p-6 relative w-full">
                <SubscriptionForm />
                <SectionBreak className="my-6" />
                <CardDetails />
              </Card>
            ) : (
              <Card className="p-4 md:p-6 relative w-full">
                <div className="flex mb-6 justify-between items-center">
                  <Heading size={3} className="text-gray-700">
                    Subscriptions
                  </Heading>
                </div>
                <p>Subscriptions cannot be managed within the app.</p>
              </Card>
            )}
          </div>
        </div>
      </FrostedPanel>
    </Page>
  );
};

export default Account;
