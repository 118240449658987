import { Card } from "@components/layout";
import { useSelectedTank } from "@hooks/useSelectedTank";
import LoadingSpinner from "@components/LoadingSpinner";
import { useNotificationRulesStore } from "../../../../state/notificationRules.store";
import { RuleSection } from "./RuleSection";

export const TankNotificationSettings = () => {
  const { selectedTank } = useSelectedTank();
  const notificationRules = useNotificationRulesStore(
    (state) => state.notificationRules?.[selectedTank?.id || ""],
  );

  return (
    <div className="flex flex-col gap-4 px-2 mt-3 sm:px-4 overflow-x-hidden">
      <Card className="w-full bg-white p-4 rounded-lg">
        <div className="mb-4">
          <div className="font-semibold text-gray-700 mb-2 text-lg">
            Basic Rules
          </div>
          {!notificationRules ? (
            <LoadingSpinner />
          ) : (
            <RuleSection
              ruleType="basic"
              notificationRules={Object.values(notificationRules).filter(
                (rule) => rule.basic,
              )}
              isEditable={false}
            />
          )}
        </div>
        <hr />
        <div className="flex flex-col my-2 gap-2">
          <p className="font-semibold text-gray-700 text-lg">Custom Rules</p>
          {!notificationRules ? (
            <LoadingSpinner />
          ) : (
            <RuleSection
              ruleType="custom"
              notificationRules={Object.values(notificationRules).filter(
                (rule) => !rule.basic,
              )}
              isEditable={true}
            />
          )}
        </div>
      </Card>
    </div>
  );
};
