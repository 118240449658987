import { Auth } from "aws-amplify";
import { useState } from "react";
import * as Yup from "yup";
import { useFormSection } from "@hooks/useFormSection";
import {
  Button,
  EditableFormSection,
  InputWrapper,
  TextField,
} from "@components/form";

const changePasswordFormSchema = Yup.object({
  oldPassword: Yup.string().required("Old Password is required"),
  newPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/.*?\d.*?/, "Password must contain at least 1 number")
    .matches(/.*?[A-Z].*?/, "Password must contain at least 1 uppercase letter")
    .matches(
      /.*?[a-z].*?/,
      "Password must contain at least 1 lowercase letter",
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords do not match")
    .required("Please confirm your password"),
});

type FormSchema = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export const ChangePasswordForm = () => {
  const [isFormShowing, setIsFormShowing] = useState(false);

  const {
    getInputWrapperProps,
    getBasicFieldProps,
    isLoading,
    setIsLoading,
    isEditing,
    setIsEditing,
    submitForm,
    values,
    resetForm,
    setFieldValue,
  } = useFormSection({
    schema: changePasswordFormSchema,
    defaultValues: { oldPassword: "", newPassword: "", confirmNewPassword: "" },
    onSubmit: async (newValues) => {
      try {
        await Auth.changePassword(
          await Auth.currentAuthenticatedUser(),
          newValues.oldPassword,
          newValues.newPassword,
        );
      } catch (e: any) {
        console.error(e);
      }

      setIsFormShowing(false);
    },
  });

  if (!isFormShowing)
    return (
      <Button
        color="gray"
        buttonStyle="secondary"
        className="px-2 py-1"
        onClick={() => setIsFormShowing(true)}
      >
        Change Password
      </Button>
    );

  return (
    <EditableFormSection
      isEditing={true}
      onCancel={() => {
        setIsFormShowing(false);
        resetForm();
      }}
    >
      <InputWrapper title="Old Password" className="col-span-1 md:col-span-2">
        <TextField
          className="w-full"
          type="password"
          {...getBasicFieldProps("oldPassword")}
        />
        <></>
      </InputWrapper>
      <InputWrapper title="New Password" className="col-span-1 md:col-span-2">
        <TextField
          className="w-full"
          type="password"
          {...getBasicFieldProps("newPassword")}
        />
        <></>
      </InputWrapper>
      <InputWrapper
        title="Confirm New Password"
        className="col-span-1 md:col-span-2"
      >
        <TextField
          className="w-full"
          type="password"
          {...getBasicFieldProps("confirmNewPassword")}
        />
        <></>
      </InputWrapper>
    </EditableFormSection>
  );
};
