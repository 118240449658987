import React, { useCallback, useEffect } from "react";
import cx from "classnames";
import { Notification } from "../components/Notification";
import {
  useNotificationsStore,
  Notification as TNotification,
} from "../state/notifications.store";
import LoadingSpinner from "./LoadingSpinner";
import { Button } from "./form";
type NotificationsPanelProps = {
  isActive?: boolean;
};

const PanelContent = ({
  notifications,
  notificationsObject,
}: {
  notifications: TNotification[];
  notificationsObject?: Record<string, TNotification>;
}) => {
  if (!notificationsObject) return <LoadingSpinner />;
  if (notifications.length < 1)
    return (
      <div className="px-4 py-2 text-md">You have no unread notifications.</div>
    );
  return (
    <div className="pt-1">
      {notifications
        .sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime())
        .map((notification) => (
          <div key={notification.id} className="flex px-4 py-2 text-md">
            <Notification
              title={notification.title}
              body={notification.body}
              time={notification.time}
              read={notification.read === "true"}
              className="w-64 sm:w-full md:max-w-3xl max-w-lg"
            />
          </div>
        ))}
    </div>
  );
};

export const NotificationsPanel = ({
  isActive = false,
}: NotificationsPanelProps) => {
  const notificationsObject = useNotificationsStore(
    (state) => state.notifications,
  );
  const markNotificationRead = useNotificationsStore(
    (state) => state.markNotificationRead,
  );

  const fetchNotifications = useNotificationsStore(
    (state) => state.fetchNotifications,
  );

  const notifications = Object.values(notificationsObject || {});

  const markAllRead = useCallback(() => {
    notifications.forEach(({ id }) => markNotificationRead({ id }));
  }, [notifications, markNotificationRead]);

  // TODO: Move this to a provider
  useEffect(() => {
    if (!notificationsObject) fetchNotifications({});
  }, [notificationsObject, fetchNotifications]);

  return (
    <div
      className={cx(
        "absolute right-0 h-full py-2 mt-2 bg-white rounded-md overflow-hidden shadow-xl flex flex-col z-20 w-72 sm:w-max min-h-[50vh]",
        isActive ? null : "hidden",
      )}
    >
      <span className="px-4 pt-2 pb-3 flex flex-row justify-between">
        <h3 className="font font-semibold">Your notifications</h3>
        <Button buttonStyle="tertiary" color="fathomiser" onClick={markAllRead}>
          Mark Read
        </Button>
      </span>
      <div className="overflow-y-scroll h-full">
        <PanelContent
          notifications={notifications}
          notificationsObject={notificationsObject}
        />
      </div>
    </div>
  );
};
