import { API } from "aws-amplify";
import { omit } from "lodash";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { apiName } from "../config";

export type NotificationRule = {
  id: string;
  tankId: string;
  field: string;
  basic: boolean;
  enabled: boolean;
  delta: number;
  time: number;
  operator: string;
  deltaUnit: string;
  timeUnit: string;
};

type NotificationRulesState = {
  notificationRules?: Record<string, Record<string, NotificationRule>>;
  fetchNotificationRule: (opts: {
    tankId: string;
    id: string;
  }) => Promise<Error | void>;
  toggleNotificationRule: (opts: {
    tankId: string;
    id: string;
    enabled: boolean;
  }) => Promise<Error | void>;
  updateNotificationRule: (opts: {
    notificationRule: NotificationRule;
  }) => Promise<Error | void>;
  deleteNotificationRule: (opts: {
    tankId: string;
    id: string;
  }) => Promise<Error | void>;
  createNotificationRule: (opts: {
    notificationRule: Omit<NotificationRule, "id">;
  }) => Promise<Error | void>;
  fetchNotificationRules: (opts: { tankId: string }) => Promise<Error | void>;
};

export const useNotificationRulesStore = create<NotificationRulesState>()(
  devtools(
    (set, get) => ({
      notificationRules: undefined,
      fetchNotificationRule: async ({ tankId, id }) => {
        console.log("get notification rule");

        return API.get(apiName, `/tanks/${tankId}/notification-rules/${id}`, {})
          .catch((err) => {
            console.log({
              err,
            });
            return err;
          })
          .then((notificationRule: NotificationRule) => {
            set((state) => ({
              notificationRules: {
                ...state.notificationRules,
                [tankId]: {
                  ...state.notificationRules?.[tankId],
                  [id]: notificationRule,
                },
              },
            }));
            return undefined;
          });
      },

      toggleNotificationRule: async ({ tankId, id, enabled }) => {
        console.log("Toggle Notification rules");
        const existingNotificationRule =
          get().notificationRules?.[tankId]?.[id];
        if (!existingNotificationRule) return; // TODO: Error handling
        set((state) => ({
          notificationRules: {
            ...state.notificationRules,
            [tankId]: {
              ...state.notificationRules?.[tankId],
              [id]: { ...existingNotificationRule, enabled },
            },
          },
        }));

        get().updateNotificationRule({
          notificationRule: { ...existingNotificationRule, enabled },
        });
      },

      updateNotificationRule: async ({ notificationRule }) => {
        console.log("update notification rule");
        const { tankId, id } = notificationRule;
        return API.patch(apiName, `/tanks/${tankId}/notification-rules/${id}`, {
          body: notificationRule,
        })
          .catch((err) => {
            console.log({
              err,
            });
            return err;
          })
          .then(() => {
            set((state) => ({
              notificationRules: {
                ...state.notificationRules,
                [tankId]: {
                  ...state.notificationRules?.[tankId],
                  [id]: notificationRule,
                },
              },
            }));

            get().fetchNotificationRules({ tankId });
            return undefined;
          });
      },
      deleteNotificationRule: async ({ tankId, id }) => {
        console.log("delete notification rule");
        return API.del(apiName, `/tanks/${tankId}/notification-rules/${id}`, {})
          .catch((err) => {
            console.log({
              err,
            });
            return err;
          })
          .then(() => {
            set((state) => ({
              ...state.notificationRules,
              [tankId]: omit(state.notificationRules?.[tankId], [id]),
            }));

            get().fetchNotificationRules({ tankId });
            return undefined;
          });
      },
      createNotificationRule: async ({ notificationRule }) => {
        console.log("create notification rule");
        const { tankId } = notificationRule;
        return API.post(apiName, `/tanks/${tankId}/notification-rules`, {
          body: notificationRule,
        }).then(
          () => {
            get().fetchNotificationRules({ tankId });
            return undefined;
          },
          (err) => {
            console.log({
              err,
            });
            return err;
          },
        );
      },
      fetchNotificationRules: async ({ tankId }) => {
        console.log("fetch notification rules");
        return API.get(apiName, `/tanks/${tankId}/notification-rules`, {})
          .catch((err) => {
            console.log({
              err,
            });
            return err;
          })
          .then((rules: NotificationRule[]) => {
            set((state) => ({
              notificationRules: {
                ...state.notificationRules,
                [tankId]: rules.reduce(
                  (acc, rule) => ({
                    ...acc,
                    [rule.id]: rule,
                  }),
                  {},
                ),
              },
            }));
            return undefined;
          });
      },
    }),
    {
      name: "NotificationRulesStore",
    },
  ),
);
