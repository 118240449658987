import React from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { NotificationRule } from "../state/notificationRules.store";
import { Button } from "./form";
import { ToggleButton } from "./form/Toggle";
import {
  deltaUnitOptions,
  fieldOptions,
  operatorOptions,
  timeUnitOptions,
} from "./RulesEditor";

export type UneditableRuleProps = {
  rule: NotificationRule;
  isEditable?: boolean;
  onClick?: (value: boolean) => void;
  onEdit: () => void;
};

export const UneditableRule = ({
  rule,
  isEditable,
  onClick,
  onEdit,
}: UneditableRuleProps) => {
  const { field, enabled, operator, delta, deltaUnit, time, timeUnit } = rule;
  const rawMessage = [
    "Notify when",
    fieldOptions.find((f) => f.value === field)?.label,
    operatorOptions.find((f) => f.value === operator)?.label,
    `${delta}${deltaUnitOptions.find((f) => f.value === deltaUnit)?.label}`,
    "over",
    time,
    timeUnitOptions.find((f) => f.value === timeUnit)?.label,
  ].join(" ");

  const message =
    rawMessage[0].toUpperCase() + rawMessage.slice(1).toLowerCase();
  return (
    <div className="flex flex-row items-center justify-between border rounded-md p-3 space-x-3">
      <span className="flex flex-row gap-2 items-center">
        <ToggleButton
          value={enabled}
          onClick={onClick}
          className="flex flex-shrink-0 "
        />
        <p className="flex-shrink text-gray-600">{message}</p>
      </span>
      {isEditable && (
        <Button
          onClick={() => {
            onEdit();
          }}
          className=" p-1"
          color="gray"
          buttonStyle="secondary"
        >
          <HiOutlinePencilAlt size="1.4rem" />
        </Button>
      )}
    </div>
  );
};
