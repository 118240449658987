import { API } from "aws-amplify";
import { omit } from "lodash";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { apiName } from "../config";

export type Tank = {
  id: string;
  name: string;
  color: string;
  shape: string;
  description?: string;
  height?: number;
  overflowDistance?: number;
  waterSource: string;
  diameter?: number;
  width?: number;
  length?: number;
  imei?: string;
  volume: number;
  sensorModel?: string;
  measurementInterval: number;
  checkInInterval: number;
  isPublic: boolean;
};

export type TankOverview = {
  currentVolume: number;
  usage: number;
  averageDailyUsage: number;
  estimatedDaysRemaining: number;
  lastActive?: string;
  batteryVoltage?: number;
};

type TanksState = {
  tankOverviews?: Record<string, TankOverview>;
  selectedTankId?: string;
  tanks?: Record<string, Tank>;
  selectTank: (opts: { id: string }) => void;
  fetchTanks: () => Promise<Error | void>;
  createTank: (opts: { tank: Tank }) => Promise<Error | void>;
  fetchTank: (opts: { id: string }) => Promise<Error | void>;
  fetchTankOverview: (opts: { id: string }) => Promise<Error | void>;
  updateTank: (opts: { tank: Tank }) => Promise<Error | void>;
  deleteTank: (opts: { id: string }) => Promise<Error | void>;
  fetchTankOverviews: () => void;
};

export const useTanksStore = create<TanksState>()(
  devtools(
    (set, get) => ({
      tanks: undefined,
      selectedTankId: undefined,
      tankOverviews: undefined,
      selectTank: ({ id }) => set({ selectedTankId: id }),
      fetchTankOverview: async ({ id }) => {
        console.log("fetch tank overview");
        return API.get(apiName, `/tanks/${id}/overview`, {}).then(
          (overview: TankOverview) => {
            set((state) => ({
              tankOverviews: { ...(state.tankOverviews || {}), [id]: overview },
            }));
            return undefined;
          },
          (err) => {
            console.error({ err });
            return err;
          },
        );
      },
      fetchTankOverviews: async () => {
        const { tanks, fetchTankOverview } = get();
        if (!tanks) return;
        const tanksArr = Object.values(tanks);
        tanksArr.forEach((tank) => fetchTankOverview({ id: tank.id }));
      },
      fetchTanks: async () => {
        console.log("fetch tanks");
        return API.get(apiName, "/tanks", {}).then(
          (tanks: Tank[]) => {
            console.log({ tanks });
            set({
              tanks: tanks.reduce((acc, t) => ({ ...acc, [t.id]: t }), {}),
            });
            return undefined;
          },
          (err) => {
            console.error({ err });
            return err;
          },
        );
      },
      createTank: async ({ tank }) => {
        console.log("create tank");
        return API.post(apiName, "/tanks", { body: tank }).then(
          (retTank: Tank) => {
            set((state) => ({
              tanks: { ...state.tanks, [retTank.id]: retTank },
            }));
            // send async fetch to ensure state sync
            get().fetchTanks();
            return undefined;
          },
          (err) => {
            console.error({ err });
            return err;
          },
        );
      },
      fetchTank: async ({ id }) => {
        console.log("fetch tank");
        return API.get(apiName, `/tanks/${id}`, {}).then(
          (tank: Tank) => {
            set((state) => ({
              tanks: { ...state.tanks, [tank.id]: tank },
            }));
            return undefined;
          },
          (err) => {
            console.error({ err });
            return err;
          },
        );
      },
      updateTank: async ({ tank }) => {
        console.log("update tank", { tank });
        return API.patch(apiName, `/tanks/${tank.id}`, { body: tank }).then(
          () => {
            //faithful set state
            set((state) => ({
              tanks: { ...state.tanks, [tank.id]: tank },
            }));

            // send async fetch to ensure state sync
            get().fetchTank({ id: tank.id });
            return undefined;
          },
          (err) => {
            console.error({ err });
            return err;
          },
        );
      },
      deleteTank: async ({ id }) => {
        console.log("update tank");
        return API.del(apiName, `/tanks/${id}`, {}).then(
          () => {
            set((state) => ({
              tanks: omit(state.tanks, [id]),
              selectedTankId: undefined,
            }));

            // ensure state sync
            get().fetchTanks();
            return undefined;
          },
          (err) => {
            console.error({ err });
            return err;
          },
        );
      },
    }),
    {
      name: "TanksStore",
    },
  ),
);
