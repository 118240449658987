/* eslint-disable react/display-name */
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
  TooltipProps,
} from "recharts";
import dateFormat from "dateformat";
import { useMemo } from "react";
import { useScreen } from "@hooks/useScreen";
import { TankData } from "../../types/tank-data";
import { Heading, Subheading } from "../typography";
import { Tank } from "../../state/tanks.store";

const getTicks = (data: TankData[]) => {
  const sorted = data.map((item) => new Date(item.time).getTime()).sort();
  const interval = (0.9 * (sorted[sorted.length - 1] - sorted[0])) / 4;

  const arr = Array.from(Array(4).keys()).map(
    (_, index) => sorted[0] + (index + 1) * interval,
  );

  console.log({ arr: arr.map((d) => new Date(d)) });
  return arr;
};

type LineChartProps = {
  data: TankData[];
  tank: Tank;
};

const createCustomTooltip =
  (selectedTank?: Tank) =>
  ({ active, label, payload }: TooltipProps) => {
    if (active && payload?.length) {
      return (
        <div className="bg-white border border-gray-300 rounded-md shadow-md p-4 flex flex-col gap-y-2">
          <Heading size={3}>{selectedTank?.name}</Heading>
          <p className="text-gray-600">
            <span
              style={{ color: selectedTank?.color }}
              className="font-semibold"
            >
              {payload[0].value}
              {payload[0].unit}
            </span>
            at {dateFormat(label, "ddd dS mmm h:MMtt")}
          </p>
        </div>
      );
    }

    return null;
  };

export const LineChart = ({ data, tank }: LineChartProps) => {
  const { isSm } = useScreen();
  const CustomTooltip = useMemo(() => createCustomTooltip(tank), [tank]);

  if (!tank || !data) return <></>;

  const usedData = useMemo(
    () =>
      data.map((entry) => ({
        ...entry,
        data: Math.round((entry.data / (tank!.volume || 1)) * 100),
        time: new Date(entry.time).getTime(),
      })),
    [data],
  );

  const ticks = useMemo(() => getTicks(data), [data]);

  console.log({ ticks });
  return data.length > 0 ? (
    <ResponsiveContainer
      minHeight={300}
      height="100%"
      width="100%"
      className="mb-4"
      debounce={2}
    >
      <AreaChart
        data={usedData}
        margin={{ top: 13, right: 0, left: 0, bottom: 2 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="time"
          type="number"
          domain={["dataMin", "dataMax"]}
          padding={{ right: 25 }}
          tickFormatter={(date) => dateFormat(date, "dS mmm")}
          allowDuplicatedCategory={true}
          tickMargin={12}
          ticks={ticks}
          interval={0}
        />
        <YAxis
          dataKey="data"
          tickCount={5}
          tickFormatter={(t) => `${t}%`}
          hide={isSm ? false : true}
          tickMargin={12}
        />
        <Tooltip
          content={CustomTooltip}
          coordinate={{ x: 10, y: 20 }}
          allowEscapeViewBox={{ x: false, y: false }}
        />

        <Area
          type="basis"
          dataKey="data"
          fill="url(#currentTankArea)"
          stroke={tank?.color}
          strokeWidth={3}
          name={tank?.name}
          unit="%"
        />
        <defs>
          <linearGradient id="currentTankArea" x1="0" y1="0" x2="0" y2="1">
            <stop offset="10%" stopColor={tank?.color} stopOpacity={0.2} />
            <stop offset="85%" stopColor={tank?.color} stopOpacity={0} />
          </linearGradient>
        </defs>
      </AreaChart>
    </ResponsiveContainer>
  ) : (
    <div className="min-h-[300px] rounded-md w-full border border-gray-300 border-dashed flex flex-col gap-y-2 py-8 justify-center items-center bg-gray-200 bg-opacity-30">
      <Heading className="text-center" size={2}>
        Oops! Looks like there isn&apos;t any data.
      </Heading>
      <Subheading>Try a different period of time instead.</Subheading>
    </div>
  );
};
