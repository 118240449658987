import { useParams } from "react-router-dom";
import { App } from "@capacitor/app";
import {
  DateRangePickerDropdown,
  FrostedPanel,
  Heading,
  LineChart,
  Page,
  Subheading,
} from "@components";
import { TbDroplet } from "react-icons/tb";
import { HiOutlineSun } from "react-icons/hi";
import { useEffect, useMemo, useState } from "react";
import { usePublicTank } from "../../state/publicTank.store";
import LoadingScreen from "../Loading";
import { StatCardCarousel } from "../home/tabs/tank-info/StatCardCarousel";
import { TankStatCard } from "../home/tabs/tank-info/TankStatCard";
import { toOnePercent } from "../../utils/toOnePercent";
import dateFormat from "dateformat";

const SharedTank = () => {
  const { tankId } = useParams<{ tankId: string | undefined }>();
  const {
    setTank,
    tankId: storedPublicTankId,
    tank,
    fetchData,
    overview,
  } = usePublicTank();
  const [month, setMonth] = useState(new Date());
  const [dateRange, setDateRange] = useState<{ from?: Date; to?: Date }>({
    from: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
    to: new Date(),
  });

  const data = usePublicTank(({ tankData }) => tankData);

  useEffect(() => {
    if (tankId == null) {
      return () => {};
    }

    if (tankId !== storedPublicTankId) {
      setTank({ id: tankId });
    }
  }, [storedPublicTankId, tankId, setTank]);

  useEffect(() => {
    const listener = App.addListener("resume", () => {
      console.log("resume");
      if (!tankId || !dateRange.from || !dateRange.to) return;

      fetchData({
        startTime: dateRange.from,
        endTime: dateRange.to,
      });
    });
    return () => {
      listener.then((x) => x.remove());
    };
  }, [tankId, dateRange, setDateRange, fetchData]);

  useEffect(() => {
    if (!tankId || !dateRange.from || !dateRange.to || !storedPublicTankId)
      return;

    fetchData({ startTime: dateRange.from, endTime: dateRange.to });
  }, [tankId, dateRange, fetchData, storedPublicTankId]);

  if (!tankId) {
    return <h1>Tank not found</h1>;
  }

  if (!storedPublicTankId || !tank || !data) {
    return <LoadingScreen />;
  }

  const tankName = useMemo(() => {
    if (tank.name.length > 30) {
      return tank.name.substring(0, 30) + "...";
    }

    return tank.name;
  }, [tank]);

  return (
    <Page navigation={false}>
      <FrostedPanel
        ratio={0.3}
        className="sm:mb-8 sm:mt-0 frosted-pane"
        style={{ minHeight: 500 }}
      >
        <div className="w-full flex flex-col gap-y-4 pb-4 h-full sm:h-auto sm:mb-8">
          <div className="flex flex-col items-center md:flex-row justify-between bg-white shadow-sm sm:shadow-none z-[10] py-2 md:px-6 md:py-4">
            <Heading size={2}>{tankName}</Heading>
            <Subheading>
              Last updated:{" "}
              {dateFormat(
                new Date(overview?.lastActive!),
                "h:MMtt dS mmmm yyyy",
              )}
            </Subheading>
          </div>

          <StatCardCarousel rows={2}>
            <TankStatCard
              icon={TbDroplet}
              iconClassName="text-blue-600 bg-blue-100"
              value={toOnePercent(overview?.currentVolume, tank?.volume!) + "%"}
              heading="Current Volume"
            />

            <TankStatCard
              icon={TbDroplet}
              iconClassName="text-emerald-600 bg-emerald-100"
              value={
                toOnePercent(overview?.averageDailyUsage, tank?.volume) + "%"
              }
              heading="Average Change"
              subheading="Daily avg. (7d)"
              arrowDirection={
                (overview?.averageDailyUsage || 1) > 0 ? "up" : "down"
              }
            />

            <TankStatCard
              icon={HiOutlineSun}
              iconClassName="text-amber-600 bg-amber-100"
              value={toOnePercent(overview?.usage, tank?.volume) + "%"}
              heading="Recent Change"
              subheading="24hrs"
              arrowDirection={(overview?.usage || 1) > 0 ? "up" : "down"}
            />
          </StatCardCarousel>

          <hr className="mt-2 mx-2 border-t border-gray-300" />
          <div className=" px-2 sm:px-4 w-full flex-grow flex flex-col gap-y-2">
            <div className="flex flex-row justify-end">
              <DateRangePickerDropdown
                disabledDays={{ after: new Date() }}
                month={month}
                fromMonth={new Date(2000, 0, 0)}
                onMonthChange={(m) => setMonth(m)}
                onChange={(dates) => setDateRange(dates)}
                from={dateRange.from}
                to={dateRange.to}
              />
            </div>
            <LineChart data={data || []} tank={tank} />
          </div>
        </div>
      </FrostedPanel>
    </Page>
  );
};

export default SharedTank;
