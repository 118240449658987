import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { createSetupSession } from "@services/subscriptions";
import { Button, EditableFormSectionProps } from "@components/form";
import { StripeConfig } from "../../config";

export const PaymentDetailsForm = (
  props: Partial<EditableFormSectionProps>,
) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="flex flex-row justify-end gap-6 w-full">
      <Button
        className="px-2 py-2"
        color="grey"
        buttonStyle="secondary"
        loading={loading}
        onClick={() => {
          props?.onCancel?.();
        }}
      >
        Skip
      </Button>
      <Button
        className="px-4 py-2"
        color="fathomiser"
        buttonStyle="primary"
        loading={loading}
        onClick={() => {
          props?.onCancel?.();
          return;
          /*
           * Stuff for when I get around to fixing strip (ignore for now
          setLoading(true);
          loadStripe(StripeConfig.publishableKey).then(async (stripe) => {
            const sessionId = await createSetupSession();
            await stripe?.redirectToCheckout({ sessionId });
          });
          */
        }}
      >
        Add card details
      </Button>
    </div>
  );
};
