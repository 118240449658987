import React from "react";
export type HeadingProps = {
  size: 1 | 2 | 3 | 4 | 5 | 6;
} & React.HTMLProps<HTMLHeadingElement>;

const classNames: Record<HeadingProps["size"], string> = {
  1: "",
  2: "text-xl font-semibold",
  3: "text-lg font-semibold",
  4: "font-semibold text-base",
  5: "font-semibold text-sm",
  6: "",
};

export const Heading = ({
  size,
  children,
  className,
  ...rest
}: HeadingProps) => {
  return React.createElement(
    `h${size}`,
    { className: `${classNames[size]} ${className}`, ...rest },
    children,
  );
};
