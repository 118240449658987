import React from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";

import { Button } from "./Button";

export type EditableFormSectionProps = {
  children: React.ReactNode;
  isEditing?: boolean;
  isEditable?: boolean;
  isSaveLoading?: boolean;
  submitText?: string;
  cancelText?: string;
  onEdit?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
};

export const EditableFormSection = ({
  children,
  isEditing = false,
  isEditable = true,
  isSaveLoading = false,
  submitText = "Submit",
  cancelText = "Cancel",
  onEdit,
  onSave,
  onCancel,
}: EditableFormSectionProps) => {
  return (
    <div className="relative w-full">
      {isEditable && !isEditing && (
        <span className="absolute right-3 top-3">
          <Button
            onClick={onEdit}
            className=" p-1"
            color="gray"
            buttonStyle="secondary"
          >
            <HiOutlinePencilAlt size="1.4rem" />
          </Button>
        </span>
      )}

      {children}

      {isEditing && (
        <div className="pt-5 flex flex-row justify-end gap-2">
          <Button
            className="px-2 py-1"
            buttonStyle="secondary"
            color="red"
            onClick={onCancel}
            type="button"
          >
            {cancelText}
          </Button>
          <Button
            loading={isSaveLoading}
            onClick={onSave}
            className="px-4 py-1"
          >
            {submitText}
          </Button>
        </div>
      )}
    </div>
  );
};
