/* eslint-disable react/no-unstable-nested-components */
import Select, {
  components,
  MenuProps,
  ValueContainerProps,
} from "react-select";
import dateFormat from "dateformat";
import cx from "classnames";
import { Button } from "./Button";
import { DateRangePicker, DateRangePickerProps } from "./DateRangePicker";
import { CustomOption } from "./Dropdown";

type DateRangePickerDropdownProps = { error?: boolean } & DateRangePickerProps;

export const DateRangePickerDropdown = ({
  error,
  className,
  ...dateRangePickerProps
}: DateRangePickerDropdownProps) => {
  const Menu = (props: MenuProps<CustomOption, false>) => {
    return (
      <components.Menu {...props} className={`mt-2 ${props.className}`}>
        <div className="bg-white border-gray-200 border shadow-md absolute right-0 rounded-lg flex flex-col">
          <DateRangePicker {...dateRangePickerProps} />
          <Button
            className="mx-2 mb-2 p-1"
            color="gray"
            buttonStyle="secondary"
            onClick={() =>
              dateRangePickerProps.onChange({
                from: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
                to: new Date(),
              })
            }
          >
            Last 24 hours
          </Button>
          <Button
            className="mx-2 mb-2 p-1"
            color="gray"
            buttonStyle="secondary"
            onClick={() =>
              dateRangePickerProps.onChange({
                from: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
                to: new Date(),
              })
            }
          >
            Last 7 days
          </Button>
          <Button
            className="mx-2 mb-2 p-1"
            color="gray"
            buttonStyle="secondary"
            onClick={() =>
              dateRangePickerProps.onChange({
                from: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
                to: new Date(),
              })
            }
          >
            Last 30 days
          </Button>
        </div>
      </components.Menu>
    );
  };

  const Placeholder = (props: ValueContainerProps<CustomOption, false>) => {
    const { value } = props.getValue()[0];
    return (
      <div
        className={`flex flex-row gap-x-1 ${
          error ? "text-red-700" : "text-gray-700"
        }`}
      >
        <p className="font-semibold">
          {value.from ? dateFormat(value.from, "ddd ddS mmm") : ""}
        </p>
        to
        <p className="font-semibold">
          {value.to ? dateFormat(value.to, "ddd ddS mmm") : ""}
        </p>
      </div>
    );
  };

  return (
    <Select
      components={{ Menu, Placeholder }}
      isSearchable={false}
      menuPortalTarget={document.body}
      controlShouldRenderValue={false}
      closeMenuOnSelect={false}
      classNames={{
        control: (props) =>
          cx(
            "border hover:outline-none shadow-none !cursor-pointer rounded-md",
            {
              "bg-red-100 border-red-700": error,
              "bg-white border-gray-200": !error,
            },
          ),
        valueContainer: (props) =>
          cx("p-2 !flex", {
            "border-red-700": error,
            "border-gray-200": !error,
          }),
        indicatorSeparator: (props) => cx("hidden"),
        dropdownIndicator: (props) =>
          cx("px-2", {
            "text-red-700": error,
            "text-gray-200 hover:text-gray-700": !error,
          }),
        menuPortal: () => "!z-[400]",
      }}
      unstyled={true}
      value={{
        label: "",
        value: { to: dateRangePickerProps.to, from: dateRangePickerProps.from },
      }}
    />
  );
};
