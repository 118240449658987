import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import RulesEditor from "@components/RulesEditor";
import { UneditableRule } from "@components/UneditableRule";
import { Button } from "@components/form";
import { Heading, Subheading } from "@components/typography";
import { useTanksStore } from "../../../../state";
import {
  NotificationRule,
  useNotificationRulesStore,
} from "../../../../state/notificationRules.store";
export type RuleSectionProps = {
  notificationRules: NotificationRule[];
  isEditable: boolean;
  ruleType: "basic" | "custom";
};
export const RuleSection = ({
  notificationRules,
  isEditable,
  ruleType,
}: RuleSectionProps) => {
  const [editingRules, setEditingRules] = useState<Record<string, boolean>>({});
  const selectedTankId = useTanksStore((state) => state.selectedTankId);
  const toggleNotificationRule = useNotificationRulesStore(
    (state) => state.toggleNotificationRule,
  );

  const createNotificationRule = useNotificationRulesStore(
    (state) => state.createNotificationRule,
  );

  return (
    <div className="flex flex-col gap-2">
      {notificationRules.length < 1 ? (
        <div className="min-h-[100px] rounded-md w-full border border-gray-300 border-dashed flex flex-col gap-y-2 py-8 justify-center items-center px-5 bg-gray-200 bg-opacity-30">
          <Heading className="text-center" size={2}>
            Looks like you have no {ruleType} rules.
          </Heading>
          {ruleType === "custom" ? (
            <Subheading className="text-center">
              You can create one with the &ldquo;Add +&rdquo; button.
            </Subheading>
          ) : (
            <></>
          )}
        </div>
      ) : (
        notificationRules.map((rule) =>
          editingRules[rule.id] ? (
            <RulesEditor
              key={rule.id}
              defaultRule={rule}
              onSave={() =>
                setEditingRules((p) => ({ ...p, [rule.id]: false }))
              }
            />
          ) : (
            <UneditableRule
              key={rule.id}
              rule={rule}
              isEditable={isEditable}
              onEdit={() => setEditingRules((p) => ({ ...p, [rule.id]: true }))}
              onClick={() =>
                toggleNotificationRule({
                  tankId: rule.tankId,
                  id: rule.id,
                  enabled: !rule.enabled,
                })
              }
            />
          ),
        )
      )}

      {isEditable && (
        <Button
          buttonStyle="secondary"
          color="fathomiser"
          className="self-end px-6 py-1 flex flex-row items-center justify-center"
          onClick={() => {
            selectedTankId &&
              createNotificationRule({
                notificationRule: {
                  tankId: selectedTankId,
                  enabled: true,
                  delta: 5,
                  deltaUnit: "%",
                  time: 1,
                  timeUnit: "days",
                  field: "tank-level",
                  operator: "increases-by",
                  basic: false,
                },
              });
          }}
        >
          <p>Add</p>
          <IoMdAdd size={18} />
        </Button>
      )}
    </div>
  );
};
