import React from "react";

export type TankChartProps = {
  percentage: number;
  color: string;
};

export const TankChart = (props: TankChartProps) => {
  const { percentage, color } = props;

  return (
    <div className="rounded-lg h-full  overflow-hidden  bg-white w-16">
      <div
        className="h-full flex flex-col-reverse relative w-full"
        style={{ backgroundColor: `${color}33` }}
      >
        <div
          style={{
            position: "absolute",
            width: 5,
            height: 3,
            backgroundColor: "white",
            top: "25%",
            left: 0,
          }}
        />
        <div
          style={{
            position: "absolute",
            width: 5,
            height: 3,
            backgroundColor: "white",
            top: "50%",
            left: 0,
          }}
        />
        <div
          style={{
            position: "absolute",
            width: 5,
            height: 3,
            backgroundColor: "white",
            top: "75%",
            left: 0,
          }}
        />
        <div
          className="w-full"
          style={{ height: `${percentage}%`, backgroundColor: color }}
        ></div>
      </div>
    </div>
  );
};
