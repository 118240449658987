import { usePlatform } from "@hooks/usePlatform";

export const FathomiserLogo = () => {
  const platform = usePlatform();

  if (platform === "web") {
    return (
      <a href="/home">
        <img
          src="/images/TEXT_WHITE.svg"
          alt="logo"
          className="sm:mr-8 h-5 cursor-pointer"
        />
      </a>
    );
  }

  return (
    <img
      src="/images/TEXT_WHITE.svg"
      alt="logo"
      className="sm:mr-8 h-5 cursor-pointer"
    />
  );
};
