import React, { useState } from "react";
import DayPicker, { DateUtils, DayPickerProps } from "react-day-picker";
import "react-day-picker/lib/style.css";

export type DateRangePickerProps = DayPickerProps & {
  from?: Date;
  to?: Date;
  onChange: (dates: { from: Date | undefined; to: Date | undefined }) => void;
};

export const DateRangePicker = (props: DateRangePickerProps) => {
  const { from, to, onChange } = props;
  const [enteredTo, setEnteredTo] = useState<Date>();

  const isSelectingFirstDay = (
    from: Date | undefined,
    to: Date | undefined,
    day: Date,
  ) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayClick = (day: Date) => {
    if (isSelectingFirstDay(from, to, day)) {
      onChange({ from: day, to: undefined });
      setEnteredTo(undefined);
    } else {
      onChange({ from, to: day });
      setEnteredTo(day);
    }
  };

  const handleDayMouseEnter = (day: Date) => {
    if (!isSelectingFirstDay(from, to, day)) {
      setEnteredTo(day);
    }
  };

  const modifiers = {
    ["always outline-none"]: () => true,
    ["start text-white bg-fathomiser-100 !rounded-l-full hover:!bg-opacity-90 hover:!bg-fathomiser-100"]:
      from,
    ["end text-white bg-fathomiser-100 !rounded-r-full hover:!bg-opacity-90 hover:!bg-fathomiser-100"]:
      [to, enteredTo],
    ["middle text-white bg-fathomiser-100 !rounded-none bg-opacity-75 hover:!bg-opacity-60 hover:!bg-fathomiser-100"]:
      (day: Date) => !!to && !!from && to > day && day > from,
    ["enteredTo text-white bg-fathomiser-100 !rounded-none bg-opacity-50"]: (
      day: Date,
    ) => !to && !!enteredTo && !!from && enteredTo > day && day > from,
    ["today !text-sky-700"]: new Date(),
  };

  return (
    <DayPicker
      numberOfMonths={1}
      fromMonth={from}
      modifiers={modifiers}
      onDayClick={handleDayClick}
      onDayMouseEnter={handleDayMouseEnter}
      {...props}
    />
  );
};
