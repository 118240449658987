import { HiOutlineTrash } from "react-icons/hi";
import cx from "classnames";

import { IoIosSave } from "react-icons/io";
import { useState } from "react";
import {
  NotificationRule,
  useNotificationRulesStore,
} from "../state/notificationRules.store";
import { CustomOption } from "./form/Dropdown";
import LoadingSpinner from "./LoadingSpinner";
import { Dropdown, NumberField, ToggleButton } from ".";

type RuleProps = {
  defaultRule: NotificationRule;
  onSave: () => void;
} & React.HTMLProps<HTMLDivElement>;

export const fieldOptions: CustomOption[] = [
  { label: "Usage", value: "usage" },
  { label: "Tank Level", value: "tank-level" },
];

export const usageOperatorOptions: CustomOption[] = [
  { label: "increases to", value: "gt" },
  { label: "decreases to", value: "lt" },
  { label: "decreases by", value: "decreases-by" },
  { label: "increases by", value: "increases-by" },
];
export const levelOperatorOptions: CustomOption[] = [
  { label: "decreases by", value: "decreases-by" },
  { label: "increases by", value: "increases-by" },
];

export const operatorOptions = [
  ...usageOperatorOptions,
  ...levelOperatorOptions,
];
export const deltaUnitOptions: CustomOption[] = [
  { label: "%", value: "%" },
  { label: "L", value: "l" },
];
export const timeUnitOptions: CustomOption[] = [
  { label: "Hours", value: "hours" },
  { label: "Days", value: "days" },
];

const RulesEditor = ({
  className,
  defaultRule,
  onSave,
  ...rest
}: RuleProps) => {
  const [rule, setRule] = useState(defaultRule);
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { enabled, delta, operator, time, timeUnit } = rule;
  const updateNotificationRule = useNotificationRulesStore(
    (state) => state.updateNotificationRule,
  );
  const deleteNotificationRule = useNotificationRulesStore(
    (state) => state.deleteNotificationRule,
  );

  return (
    <div
      className={cx(
        "flex flex-row border bg-white rounded-md items-center p-3 gap-2 relative",
        className,
      )}
      {...rest}
    >
      <div className="absolute top-0 right-0 m-2 flex flex-row justify-end gap-1 items-center">
        {saveLoading ? (
          <span>
            <LoadingSpinner className="w-5 h-5 text-fathomiser-100" />
          </span>
        ) : (
          <IoIosSave
            className="cursor-pointer text-fathomiser-100 w-5 h-5"
            onClick={() => {
              setSaveLoading(true);
              updateNotificationRule({ notificationRule: rule }).then(() => {
                setSaveLoading(false);
                onSave();
              });
            }}
          />
        )}
        {deleteLoading ? (
          <span>
            <LoadingSpinner className="w-5 h-5 text-red-500" />
          </span>
        ) : (
          <HiOutlineTrash
            className="cursor-pointer text-red-500 w-5 h-5"
            onClick={() => {
              setDeleteLoading(true);
              deleteNotificationRule({ id: rule.id, tankId: rule.tankId }).then(
                () => setDeleteLoading(false),
              );
            }}
          />
        )}
      </div>
      <div className="flex flex-col justify-center h-full">
        <ToggleButton
          value={enabled}
          onClick={() => setRule({ ...defaultRule, enabled: !enabled })}
          className="flex flex-shrink-0 "
        />
      </div>
      <div className="flex flex-row flex-wrap gap-2 mr-4 items-center">
        <p className="min-w-max text-gray-700">
          Notify if{" "}
          <span className="text-gray-800 font-semibold">tank level</span>
        </p>

        <Dropdown
          options={
            defaultRule.field === "usage"
              ? usageOperatorOptions
              : levelOperatorOptions
          }
          onItemSelect={(selected) =>
            setRule({ ...defaultRule, operator: selected })
          }
          value={operatorOptions.find((e) => e.value === operator)?.value}
        />
        <span className="flex flex-row gap-2 items-center">
          <NumberField
            className="w-10 h-10 text-center"
            onBlur={(e) => {
              const val = parseInt(e.currentTarget.value);
              if (defaultRule.deltaUnit === "%") {
                const clamped = Math.min(Math.max(val, -100), 100);
                e.currentTarget.value = String(clamped);
                setRule({
                  ...defaultRule,
                  delta: clamped,
                });
                return;
              }
              setRule({
                ...defaultRule,
                delta: val,
              });
            }}
            defaultValue={delta}
          />
          <p className="text-gray-800 font-semibold">%</p>
        </span>
        <div className="flex flex-row gap-2 items-center">
          <p className="min-w-max text-gray-700">over</p>
          <NumberField
            className="w-10 h-10 appearance-none text-center"
            onBlur={(e) => {
              const val = Math.min(
                Math.max(parseInt(e.currentTarget.value), 1),
                999,
              );
              e.currentTarget.value = String(val);
              setRule({
                ...defaultRule,
                time: val,
              });
            }}
            defaultValue={time}
          />
          <Dropdown
            options={timeUnitOptions}
            onItemSelect={(selected) =>
              setRule({ ...defaultRule, timeUnit: selected })
            }
            value={timeUnitOptions.find((e) => e.value === timeUnit)?.value}
          />
        </div>
      </div>
    </div>
  );
};

export default RulesEditor;
