import { ReactNode, useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import {
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { StatusBar } from "@capacitor/status-bar";
import "@stripe/stripe-js";
import { useQuery } from "@hooks/useQuery";
import { getPlatform } from "@hooks/usePlatform";
import { confirmUser, verifyUserAttribute } from "@services/authentication";
import { ModalContextProvider } from "@contexts/ModalContext";
import { refreshPushNotifications } from "@services/push-notifications";

import { NewTank } from "./screens/new-tank/NewTank";
import "react-tippy/dist/tippy.css";
import SharedTank from "./screens/shared-tank";
import "react-toastify/dist/ReactToastify.css";
import TankNotificationsScreen from "./screens/tank-notifications/TankNotificationsScreen";
import { useUserStore } from "./state/user.store";
import { TanksProvider } from "./state/providers/TanksProvider";
import { NotificationsProvider } from "./state/providers/NotificationsProvider";
import Home from "./screens/home/HomeScreen";
import Login from "./screens/login/LoginScreen";
import Signup from "./screens/login/SignupScreen";
import Reset from "./screens/login/ResetPasswordScreen";
import Account from "./screens/account/AccountScreen";
import config from "./config";
import LoadingScreen from "./screens/Loading";

if (getPlatform() === "android") {
  StatusBar.setOverlaysWebView({ overlay: true });
}

function App() {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      oauth: {
        domain: "authentication.fathomiser.com",
        scope: ["email", "profile", "openid"],
        redirectSignIn: "http://localhost:3000/",
        redirectSignOut: "http://localhost:3000/",
        responseType: "code",
      },
    },

    API: { endpoints: config.endpoints },
  });
  useEffect(() => {
    refreshPushNotifications();
  }, []);

  return (
    <Router>
      <ModalContextProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
        <Switch>
          <Route path="/" exact component={HasAccessToRouter} />
          <Route path="/authorization" component={HandleAuthLink} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/login" exact component={Login} />
          <Route path="/reset" exact component={Reset} />

          <Route path="/shared/:tankId" exact component={SharedTank} />
          <AuthGuard>
            <TanksProvider>
              <NotificationsProvider>
                <Route path="/home/:tankId?" component={Home} />
                <Route path="/new-tank" component={NewTank} />
                <Route path="/account" component={Account} />
                <Route
                  path="/tankNotifications"
                  component={TankNotificationsScreen}
                />
              </NotificationsProvider>
            </TanksProvider>
          </AuthGuard>
        </Switch>
      </ModalContextProvider>
    </Router>
  );
}

const AuthGuard = ({ children }: { children: ReactNode }) => {
  const user = useUserStore((state) => state.user);
  const history = useHistory();
  useEffect(() => {
    const handleLogin = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();

        const { user, fetchUser } = useUserStore.getState();
        if (!user) {
          fetchUser({ id: authenticatedUser.username });
        }
      } catch (error) {
        console.error(error);
        history.push("/login");
      }
    };
    handleLogin();
  }, [history]);
  if (!user) return <LoadingScreen />;
  return <>{children}</>;
};

const HasAccessToRouter = () => {
  const history = useHistory();
  Auth.currentAuthenticatedUser()
    .then(() => {
      history.push("/home");
    })
    .catch(() => {
      history.push("/login");
    });
  return <LoadingScreen />;
};

const HandleAuthLink = () => {
  console.log("Authorizing user");
  const [loading, setLoading] = useState(true);
  const params = useQuery();

  useEffect(() => {
    const action = params.get("action");
    // TODO: Add error handling!
    switch (action) {
      case "confirmUser": {
        const codeParameter = params.get("codeParameter");
        const username = params.get("username");
        if (!codeParameter || !username) break;
        confirmUser(username, codeParameter).finally(() => {
          setLoading(false);
          toast.success("Your account has been confirmed. Please sign in.");
        });
        break;
      }
      case "verifyUserAttribute": {
        const username = params.get("username");
        const codeParameter = params.get("codeParameter");
        const attribute = params.get("attribute");
        console.log({ codeParameter, attribute, username });
        if (!username || !attribute || !codeParameter) break;
        verifyUserAttribute(username, codeParameter, attribute).finally(() => {
          setLoading(false);
          toast.success("Your account has been confirmed. Please sign in.");
        });
        break;
      }
    }
  }, [params]);

  if (loading) return <LoadingScreen />;
  return <Redirect to="/" />;
};

export default App;
