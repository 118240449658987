import { useHistory, useLocation } from "react-router-dom";
import { HiOutlineBell, HiOutlineUserCircle } from "react-icons/hi";

import { TbDroplet } from "react-icons/tb";
import cx from "classnames";

export type MobileNavigaitonProps = {
  className?: string;
  returnToListView: () => void;
};

export const MobileNavigation = ({ className }: MobileNavigaitonProps) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div
      className={cx(
        "bg-white p-3 border border-gray-300 text-gray-400 flex flex-row justify-around self-end w-full safe-inset-bottom",
        className,
      )}
    >
      <span
        className="w-full h-full flex flex-row justify-center"
        onClick={() => {
          history.push("/tankNotifications");
        }}
        onKeyDown={(e) =>
          e.code === "Space" && history.push("/tankNotifications")
        }
        tabIndex={0}
        role="button"
      >
        <HiOutlineBell
          key={0}
          className={`h-5 cursor-pointer ${
            location.pathname === "/tankNotifications" ? " text-gray-700" : ""
          }`}
          size="1.5rem"
        />
      </span>
      <span
        className="w-full h-full flex flex-row justify-center"
        onClick={() => {
          history.push("/home");
        }}
        onKeyDown={(e) => e.code === "Space" && history.push("/home")}
        tabIndex={0}
        role="button"
      >
        <TbDroplet
          key={1}
          className={`h-5 cursor-pointer ${
            location.pathname.startsWith("/home") ? " text-gray-700" : ""
          }`}
          size="1.5rem"
        />
      </span>
      <span
        className="w-full h-full flex flex-row justify-center"
        onClick={() => {
          history.push("/account");
        }}
        onKeyDown={(e) => e.code === "Space" && history.push("/account")}
        tabIndex={0}
        role="button"
      >
        <HiOutlineUserCircle
          key={2}
          className={`h-5 cursor-pointer ${
            location.pathname === "/account" ? " text-gray-700" : ""
          }`}
          size="1.5rem"
        />{" "}
      </span>
    </div>
  );
};
