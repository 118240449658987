import { Auth } from "aws-amplify";

export default {
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_gTOKz509H",
    APP_CLIENT_ID: "3c740v2eb7e05m9ipos6a2c7kv",
    IDENTITY_POOL_ID: "ap-southeast-2:749455a3-edb7-4e58-a889-f5292f9aa8dd",
  },
  endpoints: [
    {
      name: "Fathomiser",
      endpoint: import.meta.env.VITE_API_URL,
      // endpoint: "http://localhost:3001",
      custom_header: async () => {
        return {
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
  ],
};

export const StripeConfig = {
  publishableKey:
    "pk_test_51HTyW1FUOWiJ0xEBsCtawgyqn8wccKXO0ldnDo7DKekjN3awHKYj0oXdKM9H2HfrnG5CCBWpaVHBz4eUImGXx4Rq007REOGoPJ",
};

export const api =
  "https://00q87g3tof.execute-api.ap-southeast-2.amazonaws.com/stage";

export const apiName = "Fathomiser";
