import { Capacitor } from "@capacitor/core";
import { useMemo } from "react";

export const getPlatform = () => {
  return Capacitor.getPlatform();
};

export const usePlatform = () => {
  return useMemo(() => getPlatform(), []);
};
