import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export type StatCardCarouselProps = {
  children: React.ReactNode[];
  rows: number;
};
export const StatCardCarousel = ({ children }: StatCardCarouselProps) => {
  const settings = {
    dots: true,
    slidesPerRow: 1,
    rows: 2,
    arrows: false,
    centerMode: true,
    centerPadding: "0px",
    speed: 300,
    easing: "ease-in-out",
  };

  return (
    <>
      <span className="sm:hidden">
        <Slider {...settings}>{children}</Slider>
      </span>
      <div className="hidden relative sm:grid sm:grid-cols-3 sm:grid-rows-1 gap-2 grid-rows-3 grid-cols-1 px-4">
        {children}
      </div>
    </>
  );
};
