import { FC, useRef, useState } from "react";
import { HiOutlineLogout } from "react-icons/hi";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import useOnClickOutside from "@hooks/useOnClickOutside";
import { deinitializePushNotifications } from "@services/push-notifications";
import { NotificationsPanel } from "../NotificationsPanel";
import { useNotificationsStore } from "../../state/notifications.store";
import { NavBar } from "./NavBar";
import { Button } from "@components/form";
import { FathomiserLogo } from "@components/FathomiserLogo";

export type NavigationProps = {
  className?: string;
  navigation?: boolean;
};
export const Navigation: FC<NavigationProps> = ({
  className,
  navigation = true,
}: NavigationProps) => {
  const history = useHistory();
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const notificationsObject = useNotificationsStore(
    (state) => state.notifications,
  );

  const hasUnread =
    Object.values(notificationsObject || {}).filter(
      (notification) => notification.read !== "true",
    ).length > 0;

  const ref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(ref, () => {
    setNotificationsOpen(false);
  });

  const signOut = async () => {
    await deinitializePushNotifications();
    await Auth.signOut();
    history.push("/");
  };
  return (
    <div
      className={cx(
        "flex flex-row justify-between items-center w-full sm:py-6 pb-3 safe-inset-top",
        className,
      )}
    >
      <div className="flex flex-row justify-center sm:items-center sm:justify-start w-full">
        <FathomiserLogo />
        <span className="hidden sm:inline-block">
          {navigation && <NavBar />}
        </span>
      </div>
      {navigation && (
        <div ref={ref} className="hidden relative sm:block mr-4">
          <Button
            color="white"
            buttonStyle="primary"
            onClick={() => setNotificationsOpen(!notificationsOpen)}
            className="px-5 py-1"
          >
            Notifications
            {hasUnread && (
              <span className="absolute top-0 right-0 m-1 w-3 h-3 rounded-full bg-red-500 border-2 border-white" />
            )}
          </Button>
          <NotificationsPanel isActive={notificationsOpen} />
        </div>
      )}
      {navigation && (
        <span
          className="hidden sm:flex text-white cursor-pointer whitespace-nowrap flex-row items-center font-semibold"
          onClick={signOut}
          onKeyDown={(e) => e.code === "Space" && signOut()}
          tabIndex={0}
          role="button"
        >
          <HiOutlineLogout
            size="1.5rem"
            className="mr-2 transform rotate-180"
          />
          <p>Logout</p>
        </span>
      )}
    </div>
  );
};

export default Navigation;
