import React, { ReactNode, useEffect, useState } from "react";
import { v4 } from "uuid";
import { useHistory } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useModal } from "@hooks/useModal";
import { Button, Heading, Modal } from "@components";
import {
  GeneralTankInfoForm,
  SettingsSectionProps,
} from "../home/tabs/tank-settings/GeneralTankInfoForm";
import { Tank, useTanksStore, useUserStore } from "../../state";
import { TankVolumeForm } from "../home/tabs/tank-settings/TankVolumeForm";
import { SensorInfoForm } from "../home/tabs/tank-settings/SensorInfoForm";
import { SensorUpdatesForm } from "../home/tabs/tank-settings/SensorUpdatesForm";
import { PaymentDetailsForm } from "./PaymentDetailsForm";
import { getPlatform, usePlatform } from "@hooks/usePlatform";
import { decodeSensorId } from "@services/sensors";

type SumModalForm = Omit<Tank, "imei"> & { sensorId: string };

type FormStepConfiguration = {
  title: ReactNode;
  body: ReactNode;
  form: React.FC<SettingsSectionProps<Partial<SumModalForm>>>;
  shouldCreateTank?: boolean;
  isPayment?: boolean;
};

const formSteps: FormStepConfiguration[] = [
  {
    title: "Let's get set up",
    body: `There's a few details we need to gather to get your sensor up and running. \nLet's start with how you will identify your tank in the app. `,
    form: GeneralTankInfoForm,
  },
  {
    title: "Tank Details",
    body: 'In order to show you how much water is in your tank, we need to know how big it is. \nIf your tank is not rectangular or circular, use "Custom" and input the volume and height.',
    form: TankVolumeForm,
  },
  {
    title: "Sensor Details",
    body: "Input your Fathomiser's unique code or scan the QR code.",
    form: SensorInfoForm,
  },
  {
    title: "Measurement Settings",
    body: (
      <>
        Configure the delay between water level measurements as well as the
        &apos;no-change detected&apos; reporting schedule. <br />
        <br />A longer measurement interval reduces how quickly we can detect
        leaks. <br />
        A longer check-in interval reduces our ability to detect if your device
        is offline.
        <br />
        <br />
        The shorter these values are, the more battery is used.
        <br />
        <br />
        <span className="font-semibold italic">
          We recommend you don&apos;t change these values.
        </span>
      </>
    ),
    form: SensorUpdatesForm,
    shouldCreateTank: true,
  },
];

if (getPlatform() === "web") {
  formSteps.push({
    title: "Payment Details",
    body: (
      <>
        Good news! Your Fathomiser comes with a one year subscription. Lets get
        your payment details so you don&apos;t have to worry about it next year.
        You won&apos;t be charged right now. <br />
        <span className="italic">
          The button below will take you to stripe.
        </span>
        <br />
      </>
    ),
    form: PaymentDetailsForm,
    isPayment: true,
  });
}

export const NewTankModal = ({
  children,
  className,
  ...rest
}: React.HTMLProps<HTMLDivElement>) => {
  const platform = usePlatform();
  const user = useUserStore((state) => state.user);
  const createTank = useTanksStore((state) => state.createTank);
  const history = useHistory();
  const [step, setStep] = useState(0);
  const { clearModal } = useModal();

  const [newTank, setNewTank] = useState({
    id: v4(),
    shape: "circular",
    measurementInterval: 900,
    checkInInterval: 86400,
    isPublic: false,
  } as Partial<SumModalForm>);

  useEffect(() => {
    setNewTank((tank) => ({ ...tank, userId: user?.id }));
  }, [user, setNewTank]);

  const isLastStep = step === formSteps.length - 1;
  const isSecondToLastStep = step === formSteps.length - 2;
  const isFirstStep = step === 0;
  const {
    title,
    body,
    form: FormStep,
    isPayment,
    shouldCreateTank,
  } = formSteps[step];
  return (
    <Modal className={"m-4 " + (className ?? "")} {...rest}>
      <div className="relative p-4 sm:w-full sm:max-w-[800px] max-h-[80vh] overflow-y-scroll">
        <div className="absolute right-6 top-6">
          <Button
            color="secondary"
            className="px-1 py-1 float-right mx-2 my-2 right-0"
            onClick={() => {
              clearModal();
              history.push("/");
            }}
          >
            <AiOutlineCloseCircle />
          </Button>
        </div>
        {children}
        <div className="p-4 lg:p-16 flex flex-col items-start">
          <Heading className="text-gray-600" size={2}>
            {title}
          </Heading>
          <p className="pt-2 pb-4 text-left">{body}</p>
          <FormStep
            defaultValues={newTank}
            defaultEditing={true}
            onSubmit={async (values) => {
              console.log(newTank);
              console.log("Onsubmit");
              const updatedTank = {
                ...newTank,
                ...values,
              };

              const hasPaymentDetails = false;

              if (shouldCreateTank) {
                const { sensorId, ...rest } = updatedTank;
                const insertingTank = {
                  ...rest,
                  imei: decodeSensorId(sensorId ?? "").imei,
                } as unknown as Tank;

                console.log({ insertingTank });

                createTank({ tank: insertingTank });
              }

              if (hasPaymentDetails && isPayment) {
                setStep(step + 1);
              }

              if (isLastStep) {
                clearModal();
              }

              setNewTank(updatedTank);

              setStep(step + 1);
            }}
            onCancel={() => {
              if (isFirstStep || isPayment) {
                clearModal();
                history.push("/");
              }
              setStep(step - 1);
            }}
            cancelText={isFirstStep ? "Cancel" : "Back"}
            submitText={isLastStep ? "Finish" : "Next"}
          />
        </div>
      </div>
    </Modal>
  );
};
