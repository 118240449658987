import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { Page, FrostedPanel, Button } from "../../components";
import { useUserStore } from "../../state";
import { SignupForm } from "./SignupForm";
import { Link } from "react-router-dom";

const SignupScreen = () => {
  const [emailAddress, setEmailAddress] = useState<string>();

  useEffect(() => {
    useUserStore.setState({ user: undefined });
    document.querySelector("#splash-screen")?.remove?.();
  }, []);

  // TODO: style the "we've sent you an email bit."
  const resendSignup = async () => {
    if (!emailAddress) return;
    await Auth.resendSignUp(emailAddress);
  };
  return (
    <Page navigation={false} displayNav={false}>
      <div className="fixed left-0 top-0 w-full h-full flex flex-col items-center justify-center w-full h-full p-2">
        <div className="p-4 sm:p-8  flex flex-col justify-center w-full sm:w-96 h-auto rounded-md bg-white shadow-xl space-y-4">
          <span className="w-full flex flex-row justify-center">
            <img
              src="/images/Fathomiser-TEXT.png"
              alt="logo"
              className="h-7 cursor-pointer"
            />
          </span>
          <div>
            {!emailAddress && (
              <>
                <p className="text-xl font-semibold mb-4">Create an account</p>
                <hr />
              </>
            )}
          </div>
          {emailAddress ? (
            <>
              <HiOutlineCheckCircle size="5rem" className="m-auto !my-0" />
              <h3 className="text-lg font-semibold text-center">
                We&apos;ve sent you an email!
              </h3>
              <p className="text-center">
                We&apos;ve sent you an email with a link. Once you&apos;ve
                verified your account, you&apos;ll be able to sign in.
              </p>
              <a href="/login">
                <Button className="h-12 w-full">Go back to login</Button>
              </a>
              <button
                className="self-center cursor-pointer underline text-gray-600 text-sm m-auto"
                onClick={() => resendSignup()}
              >
                Click here to resend
              </button>
            </>
          ) : (
            <>
              <SignupForm
                onComplete={(emailAddress: string) =>
                  setEmailAddress(emailAddress)
                }
              />

              <div className="w-full flex justify-between items-center text-sm ">
                <div className="flex text-gray-600 text-sm">
                  <p>Already have an account?&nbsp;</p>
                  <Link to="/login">
                    <button className="self-center cursor-pointer underline">
                      Login
                    </button>
                  </Link>
                </div>
                <Link to="/reset">
                  <button className="text-gray-600 self-center cursor-pointer underline ">
                    Reset password
                  </button>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default SignupScreen;
