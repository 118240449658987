import { Auth } from "aws-amplify";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useFormSection } from "@hooks/useFormSection";
import { InputWrapper } from "../../components/form/InputWrapper";
import { Button, TextField, PhoneInput } from "../../components";

export type SignupFormProps = {};

const formSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Email address not valid")
    .required("Email is required"),
  mobile: Yup.string().matches(
    /^\+[0-9]{0,3} [0-9]{0,14}$/g,
    "Phone number is invalid",
  ),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/.*?\d.*?/, "Password must contain at least 1 number")
    .matches(/.*?[A-Z].*?/, "Password must contain at least 1 uppercase letter")
    .matches(
      /.*?[a-z].*?/,
      "Password must contain at least 1 lowercase letter",
    ),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), null], "Passwords do not match"),
});

export const SignupForm = ({
  onComplete,
}: {
  onComplete: (emailAddress: string) => void;
}) => {
  const handleSignUp = async (values: Yup.InferType<typeof formSchema>) => {
    console.log("Handle submit");

    try {
      await Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          "custom:mobile": values.mobile,
          "custom:firstName": values.firstName,
          "custom:lastName": values.lastName,
        },
      });
      onComplete(values.email);
    } catch (e: any) {
      console.error(e);
      toast.error(
        "Something went wrong signing you up. Please try again in a few minutes, or contact us for more support.",
      );
    }
  };
  const {
    setFieldValue,
    getBasicFieldProps,
    getInputWrapperProps,
    handleSubmit,
    isLoading,
  } = useFormSection({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
    },
    schema: formSchema,
    onSubmit: handleSignUp,
  });
  return (
    <form
      onSubmit={(e) => {
        console.log("Submit!", e);
        handleSubmit(e);
      }}
      className="space-y-4"
    >
      <div className="grid grid-cols-2 gap-2">
        <InputWrapper title="First name" {...getInputWrapperProps("firstName")}>
          <></>
          <TextField
            className="w-full"
            type="text"
            placeholder="Adam"
            {...getBasicFieldProps("firstName")}
          />
        </InputWrapper>
        <InputWrapper title="Last name" {...getInputWrapperProps("lastName")}>
          <></>
          <TextField
            className="w-full"
            type="text"
            placeholder="Smith"
            {...getBasicFieldProps("lastName")}
          />
        </InputWrapper>
      </div>
      <InputWrapper title="Email" {...getInputWrapperProps("email")}>
        <></>
        <TextField
          className="w-full"
          type="email"
          noValidate={true}
          placeholder="example@email.com"
          {...getBasicFieldProps("email")}
        />
      </InputWrapper>
      <InputWrapper title="Mobile" {...getInputWrapperProps("mobile")}>
        <></>
        <PhoneInput
          className="w-full"
          type="text"
          placeholder="021 123 4567"
          setFieldValue={setFieldValue}
          {...getBasicFieldProps("mobile")}
        />
      </InputWrapper>
      <InputWrapper title="Password" {...getInputWrapperProps("password")}>
        <></>
        <TextField
          className="w-full"
          type="password"
          placeholder="********"
          {...getBasicFieldProps("password")}
        />
      </InputWrapper>
      <InputWrapper
        title="Confirm Password"
        {...getInputWrapperProps("confirmPassword")}
      >
        <></>
        <TextField
          className="w-full"
          type="password"
          placeholder="********"
          {...getBasicFieldProps("confirmPassword")}
        />
      </InputWrapper>
      <Button
        loading={isLoading}
        type="submit"
        className="w-full flex flex-row justify-center items-center bg-fathomiser-100 px-4 py-2 mt-8"
      >
        Create account
      </Button>
    </form>
  );
};
