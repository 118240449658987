import React from "react";
import cx from "classnames";

type NumberFieldProps = {
  error?: boolean;
} & React.HTMLProps<HTMLInputElement>;

export const NumberField = ({ error = false, ...props }: NumberFieldProps) => {
  const numberFieldStyles = {
    noError: "bg-white text-gray-700 placeholder-gray-300 border-gray-200",
    withError: "bg-red-100 text-red-700 placeholder-red-300 border-red-700",
  };

  return (
    <input
      type="number"
      {...props}
      className={cx(
        {
          [numberFieldStyles.withError]: error,
          [numberFieldStyles.noError]: !error,
        },
        "focus:outline-none border rounded-md text-gray-800 p-1 appearance-none",
        props.className,
      )}
    />
  );
};
