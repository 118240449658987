import React, { useEffect } from "react";
import LoadingSpinner from "@components/LoadingSpinner";
import { usePlatform } from "@hooks/usePlatform";

const LoadingScreen = () => {
  const platform = usePlatform();

  useEffect(() => {
    if (platform === "web") {
      document.querySelector("#splash-screen")?.remove();
      return () => {};
    } else {
      return () => {
        document.querySelector("#splash-screen")?.remove();
      };
    }
  }, [platform]);

  return platform === "web" ? (
    <div className="w-screen h-screen flex flex-row justify-center items-center">
      <LoadingSpinner className="w-1/2 text-fathomiser-100  lg:w-1/12" />
    </div>
  ) : (
    <></>
  );
};

export default LoadingScreen;
