import React from "react";
import cx from "classnames";
import { BaseChildProps } from "./InputWrapper";

type TextAreaProps = BaseChildProps & React.HTMLProps<HTMLTextAreaElement>;

export const TextArea = ({
  error = false,
  setFieldValue: _setFieldValueUnused,
  ...props
}: TextAreaProps) => {
  const textAreaStyles = {
    noError: "bg-white text-gray-700 placeholder-gray-300 border-gray-200",
    withError: "bg-red-100 text-red-700 placeholder-red-300 border-red-700",
  };

  return (
    <textarea
      type="text"
      {...props}
      className={cx(
        { [textAreaStyles.withError]: error, [textAreaStyles.noError]: !error },
        "focus:outline-none bg-transparent p-2 rounded-md border",
        props.className,
      )}
    />
  );
};
