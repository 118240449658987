import * as React from "react";
import cx from "classnames";
export type FrostedPanelProps = {
  children: [React.ReactNode, React.ReactNode] | React.ReactNode;
  ratio?: number;
  className?: string;
  opacityClass?: string;
} & React.HTMLProps<HTMLDivElement>;

export const FrostedPanel = ({
  children,
  ratio,
  className,
  opacityClass = "",
  ...rest
}: FrostedPanelProps) => {
  return Array.isArray(children) && ratio !== undefined ? (
    <div
      className={cx(
        "flex flex-row w-full sm:rounded-md overflow-hidden opacity-[999999] h-full sm:h-auto",
        className,
      )}
      {...rest}
    >
      <div
        className="flex flex-col bg-white overflow-y-hidden hover:overflow-y-auto overflow-x-hidden rounded-t-3xl sm:rounded-3xl"
        style={{ width: `${100 * ratio}%` }}
      >
        {children[0]}
      </div>
      <div
        className="bg-white backdrop-filter backdrop-blur-md bg-clip-padding bg-opacity-90 max-h-full overflow-y-auto overflow-x-hidden rounded-t-3xl sm:rounded-3xl"
        style={{ width: `${100 - 100 * ratio}%` }}
      >
        {children[1]}
      </div>
    </div>
  ) : (
    <div
      className={cx(
        "flex flex-row w-full sm:rounded-md overflow-hidden opacity-[999999] h-full sm:h-auto",
        className,
      )}
      {...rest}
    >
      <div
        className={cx(
          "bg-white backdrop-filter backdrop-blur-md bg-clip-padding bg-opacity-90 max-h-full overflow-y-auto overflow-x-hidden rounded-t-3xl sm:rounded-3xl",
          opacityClass,
        )}
        style={{ width: `100%` }}
      >
        {children}
      </div>
    </div>
  );
};
