import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Page } from "../../components";
import { useUserStore } from "../../state";
import { ResetPasswordForm } from "./ResetPasswordForm";

const ResetPasswordScreen = () => {
  useEffect(() => {
    useUserStore.setState({ user: undefined });
  }, []);

  return (
    <Page navigation={false} displayNav={false}>
      <div className="fixed left-0 top-0 w-full h-full flex flex-col items-center justify-center w-full h-full p-2">
        <div className="p-4 sm:p-8  flex flex-col justify-center w-full sm:w-96 h-auto rounded-md bg-white shadow-xl space-y-4">
          <span className="w-full hidden sm:flex flex-row justify-center">
            <Link className="block" to="/home">
              <img
                src="/images/Fathomiser-TEXT.png"
                alt="logo"
                className="h-7 cursor-pointer"
              />
            </Link>
          </span>
          <div className="sm:hidden">
            <Link className="block" to="/home">
              <img
                src="/images/Fathomiser-TEXT.png"
                alt="logo"
                className="mb-6 h-7 cursor-pointer"
              />
            </Link>
            <p className="text-xl font-semibold">Forgot your password?</p>
          </div>
          <hr />
          <ResetPasswordForm />
          <div className="w-full flex justify-between text-sm">
            <div className="flex text-gray-600">
              <p>No account?&nbsp;</p>
              <Link to="/signup">
                <button className="self-center cursor-pointer underline">
                  Sign up
                </button>
              </Link>
            </div>
            <Link to="/reset">
              <button className="text-gray-600 self-center cursor-pointer underline ">
                Reset password
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ResetPasswordScreen;
