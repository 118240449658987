import React from "react";
import cx from "classnames";
import dateFormat from "dateformat";

export type NotificationProps = {
  title: string;
  body: string;
  time: string;
  read: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const Notification = ({ ...props }: NotificationProps) => {
  const { title, body, time, read, className, ...rest } = props;
  const classNames: { [classnames: string]: boolean } = {
    "rounded-md select-none": true, //Other
  };
  if (className) classNames[className] = true;

  return (
    <div className={cx("relative border p-2 min-w-full", classNames)} {...rest}>
      {!read && (
        <span className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 w-3 h-3 rounded-full bg-red-500 border-2 border-white" />
      )}
      <div className="flex flex-col items-start">
        <span className="flex flex-col md:flex-row md:justify-between w-full md:items-center items-start">
          <h3 className="text-lg overflow-ellipsis font-semibold">{title}</h3>
          <p className="text-sm text-gray-500 pb-1 md:pb-0">
            {dateFormat(time, "hh:MM TT ddd dd mmm")}
          </p>
        </span>
        <div className="text-md">{body}</div>
      </div>
    </div>
  );
};
