import { NavButton } from "./NavButton";

export const NavBar = () => {
  return (
    <div className="h-8 hidden md:flex flex-row">
      <NavButton placeHolderName="/home">Home</NavButton>
      <NavButton placeHolderName="/account">My Account</NavButton>
    </div>
  );
};
