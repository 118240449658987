import { useCallback, useEffect, useState } from "react";
import { TankInfo } from "../tabs/tank-info/TankInfo";
import { TankNotificationSettings } from "../tabs/tank-notifications/TankNotificationSettings";
import { TankSettings } from "../tabs/tank-settings";
import { useTanksStore } from "../../../state";
import { Switch } from "@components/form";

const tabs = {
  TankInfo: "Overview",
  TankNotificationSettings: "Alert Settings",
  TankSettings: "Tank Settings",
};

const pages = {
  TankInfo: TankInfo,
  TankNotificationSettings: TankNotificationSettings,
  TankSettings: TankSettings,
};

export const useHomeTab = () => {
  const [tab, setTab] = useState<keyof typeof tabs>("TankInfo");
  console.log(JSON.stringify(tab));

  const resetTabs = useCallback(() => {
    setTab("TankInfo");
  }, [setTab]);

  useEffect(() => {
    return useTanksStore.subscribe((state, prevState) => {
      if (state.selectedTankId !== prevState.selectedTankId) resetTabs();
    });
  }, [resetTabs]);

  const PageContent = pages[tab];

  // Shouldn't be doing UI in VM
  const tabSwitch = (
    <Switch
      options={Object.entries(tabs).map(([value, label]) => ({ label, value }))}
      value={{ label: tabs[tab], value: tab }}
      onSelect={(o) => setTab(o.value)}
    />
  );

  return { tab, resetTabs, tabSwitch, PageContent };
};
