import { API } from "aws-amplify";

const apiName = "Fathomiser";

/**
 * Registers the FCM token.
 */
export const registerFcmToken = async (
  deviceId: string,
  fcmToken: string,
  init?: any,
) => {
  return await API.post(apiName, `/fcm-tokens/${deviceId}`, {
    body: { fcmToken },
    ...init,
  }).catch((err) => console.error(JSON.stringify(err)));
};

/**
 * Deletes the FCM token.
 */
export const deleteFcmToken = async (deviceId: string, init?: any) => {
  return await API.del(apiName, `/fcm-tokens/${deviceId}`, {
    ...init,
  });
};
