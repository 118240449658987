import React from "react";
import cx from "classnames";

export const Gradient = ({ className }: { className?: string }) => {
  return (
    <div >
      <svg
        className={cx(className, "absolute top-0 left-0 w-[500%] sm:w-full")}
        viewBox="0 100 1920 589"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0H1920V475C1920 475 1343.5 260.5 944 490C544.5 719.5 0 475 0 475V0Z"
          fill="url(#gradient)"
        />
        <defs>
          <linearGradient
            id="gradient"
            x1="1788.5"
            y1="631.062"
            x2="1831.85"
            y2="-80.3301"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0369A1" />
            <stop offset="1" stopColor="#22D3EE" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
