import React from "react";
import { IconType } from "react-icons";
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";
import { Card, Heading, Subheading } from "../../../../components";
export type TankStatCardProps = {
  icon: IconType;
  className?: string;
  iconClassName?: string;
  value: string;
  heading: string;
  subheading?: string;
  arrowDirection?: "up" | "down";
};
export const TankStatCard = ({
  icon: Icon,
  className,
  iconClassName,
  value,
  heading,
  subheading,
  arrowDirection,
}: TankStatCardProps) => {
  return (
    <Card
      className={`p-4 mx-2 sm:mx-0 mb-2 sm:mb-0 flex flex-row items-center gap-x-4 ${className} `}
    >
      <div
        className={`square rounded-md h-10 flex flex-row items-center justify-center ${iconClassName}`}
      >
        <Icon size="60%" />
      </div>
      <div className="flex flex-col justify-end w-full gap-y-1">
        <Heading size={5} className="text-gray-500">
          {heading}
        </Heading>
        <div className="flex flex-row items-end">
          <Heading size={3} className="text-gray-700 mr-1 leading-none">
            {value}
          </Heading>
          {arrowDirection &&
            (arrowDirection === "up" ? (
              <HiArrowNarrowUp className="text-green-500" size="1.3rem" />
            ) : (
              <HiArrowNarrowDown className="text-red-500" size="1.3rem" />
            ))}
          <Subheading className="leading-none">{subheading}</Subheading>
        </div>
      </div>
    </Card>
  );
};
