import React, { useContext } from "react";
import cx from "classnames";
import { Button, ButtonProps, Heading, Modal } from "@components";
import { modalContext } from "@contexts/ModalContext";

type ModalQuestion = {
  title: React.ReactNode;
  body: React.ReactNode;
  actions: (ButtonProps & { key: string })[];
};
export const useModal = () => {
  const { setModal } = useContext(modalContext);

  const createQuestion = (
    questionFn: (
      resolve: () => void,
      reject: (reason?: string) => void,
    ) => ModalQuestion,
  ) => {
    return new Promise<void>((res, rej) => {
      const question = questionFn(res, rej);
      setModal(
        <Modal className="m-4">
          <div className="relative p-4 sm:w-full sm:max-w-[800px]">
            <Heading size={3} className="mb-4">
              {question.title}
            </Heading>
            {question.body}
            <div className="flex flex-row gap-4 mt-8 justify-end">
              {question.actions.map(({ className, ...props }) => (
                <Button
                  {...props}
                  className={cx(["px-6 py-2", className])}
                  key={props.key}
                />
              ))}
            </div>
          </div>
        </Modal>,
      );
    });
  };

  const set = (modal: JSX.Element) => {
    setModal(modal);
  };

  const clearModal = () => {
    setModal(undefined);
  };

  return { createQuestion, clearModal, setModal: set };
};
