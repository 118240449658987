import React from "react";
import cx from "classnames";
import { BaseChildProps } from "./InputWrapper";

type TextFieldProps = BaseChildProps & React.HTMLProps<HTMLInputElement>;

export const TextField = ({
  error = false,
  setFieldValue: _setFieldValueUnused,
  ...props
}: TextFieldProps) => {
  const textFieldStyles = {
    noError: "bg-white text-gray-700 placeholder-gray-300 border-gray-200",
    withError: "bg-red-100 text-red-700 placeholder-red-300 border-red-700",
  };

  return (
    <input
      type="text"
      {...props}
      className={cx(
        {
          [textFieldStyles.withError]: error,
          [textFieldStyles.noError]: !error,
        },
        "focus:outline-none p-2 rounded-md border",
        props.className,
      )}
    />
  );
};
