import { Auth } from "aws-amplify";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { MdError } from "react-icons/md";
import { Button, TextField, InputWrapper } from "@components";
import { initializePushNotifications } from "@services/push-notifications";
import { useFormSection } from "@hooks/useFormSection";

export type LoginFormProps = {};

const formSchema = Yup.object({
  email: Yup.string()
    .email("Email address incorrect")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

type FormSchema = {
  email: string;
  password: string;
};
export const LoginForm = () => {
  useEffect(() => document.querySelector("#splash-screen")?.remove?.());
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState<string>("");
  const history = useHistory();
  const handleSignIn = async (values: FormSchema) => {
    setLoading(true);

    try {
      await Auth.signIn(values.email, values.password);
      await initializePushNotifications();
      history.push("/home");
    } catch (e: any) {
      console.error(e);
      if (e.message) {
        setLoginError(e.message);
      } else {
        toast.error(
          "Something went wrong whilst logging in. Please try again in a few minutes, or contact us for more support.",
        );
      }
    }
    setLoading(false);
  };

  const {
    handleChange,
    getBasicFieldProps,
    getInputWrapperProps,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormSection({
    schema: formSchema,
    defaultValues: {
      email: "",
      password: "",
    },
    onSubmit: handleSignIn,
  });
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <InputWrapper title="Email" {...getInputWrapperProps("email")}>
        <></>
        <TextField
          className="w-full"
          type="email"
          noValidate={true}
          autoCorrect="off"
          autoCapitalize="off"
          placeholder="example@email.com"
          {...getBasicFieldProps("email")}
          onBlur={async (e) => {
            await setFieldValue("email", e.currentTarget.value.trim());
            return handleBlur(e);
          }}
          error={touched.email && !!errors.email}
        />
      </InputWrapper>
      <InputWrapper title="Password" {...getInputWrapperProps("password")}>
        <></>
        <TextField
          className="w-full"
          type="password"
          placeholder="Password"
          {...getBasicFieldProps("password")}
        />
      </InputWrapper>
      {loginError && (
        <span className="bg-red-100 p-2 rounded-md text-red-700 flex flex-row items-center gap-2">
          <MdError size="1.3rem" />
          {loginError}
        </span>
      )}
      <Button
        color="fathomiser"
        loading={loading}
        className="w-full flex flex-row justify-center items-center px-4 py-2 mt-4 mb-4"
      >
        Login
      </Button>
    </form>
  );
};
