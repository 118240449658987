import React from "react";
import { Navigation } from "../navmenu/Navigation";
import { Gradient } from "../Gradient";
import { MobileNavigation } from "../navmenu/MobileNavigation";
export type PageProps = {
  children: React.ReactNode;
  navigation?: boolean;
  displayNav?: boolean;
};
export const Page = ({
  children,
  navigation = true,
  displayNav = true,
}: PageProps) => {
  return (
    <div className="bg-gray-200 w-screen h-screen overflow-hidden relative">
      <Gradient />
      <div className="absolute top-0 left-0 flex flex-row justify-center z-10 w-screen h-screen max-h-screen overflow-x-hidden ">
        <div
          className="flex flex-col justify-betweensm:px-0 w-full sm:w-11/12 max-h-full"
          style={{ maxWidth: "1500px" }}
        >
          <div className="flex flex-col h-full w-full justify-between">
            <span className="flex flex-col sm:px-2 w-full h-full overflow-hidden">
              {displayNav && (
                <Navigation className="flex" navigation={navigation} />
              )}
              {children}
            </span>
            {navigation && (
              <MobileNavigation className="sm:hidden justify-self-end" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
