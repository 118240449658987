import dateFormat from "dateformat";
import { useMemo, useState } from "react";
import humanizeDuration from "humanize-duration";
import { Button } from "@components/form";
import { SectionBreak } from "@components/layout";
import { Heading, Subheading } from "@components/typography";
import { encodeSensorId } from "@services/sensors";
import { useModal } from "@hooks/useModal";
import { cancelSubscription } from "@services/subscriptions";
import { useUserStore } from "../../state";
import { Subscription as SubscriptionType } from "../../types/user";
import { Capacitor } from "@capacitor/core";

export const SubscriptionForm = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const orderedSubscriptions = useMemo(() => {
    // if (!user?.subscriptions) return [];
    // return user?.subscriptions
    //   ?.sort((sub1, sub2) => {
    //     const sub1Date = new Date(sub1.nextBillingDate).getTime();
    //     const sub2Date = new Date(sub2.nextBillingDate).getTime();
    //     return sub1Date - sub2Date;
    //   })
    //   .map((sub) => ({
    //     ...sub,
    //     tankName: user.tanks.find((tank) => tank.imei === sub.imei)?.name,
    //   }));
    return [];
  }, []);

  return (
    <div>
      <div className="flex mb-6 justify-between items-center">
        <Heading size={3} className="text-gray-700">
          Subscriptions
        </Heading>
      </div>
      <div className="flex flex-col gap-3">
        {orderedSubscriptions.length < 1 && (
          <p className="text-gray-500">
            You have no active subscriptions. To add a new subscription, set up
            a new tank.
          </p>
        )}
        {/* {orderedSubscriptions.length > 0 &&
          (isCollapsed ? (
            <Subscription
              key={orderedSubscriptions[0].imei}
              {...orderedSubscriptions[0]}
            />
          ) : (
            orderedSubscriptions?.map((sub) => (
              <Subscription key={sub.imei} {...sub} />
            ))
          ))} */}
        {orderedSubscriptions && orderedSubscriptions.length > 1 && (
          <Button
            buttonStyle="secondary"
            color="gray"
            className="w-full  px-2 py-1"
            onClick={() => {
              setIsCollapsed(!isCollapsed);
            }}
          >
            {isCollapsed
              ? `Show more (${orderedSubscriptions.length - 1})`
              : "Show less"}
          </Button>
        )}
      </div>
    </div>
  );
};

type SubscriptionProps = SubscriptionType & {
  tankName?: string;
};

export const Subscription = ({
  id,
  tankName,
  imei,
  billingAmount,
  billingFrequency,
  nextBillingDate,
}: SubscriptionProps) => {
  const { createQuestion, clearModal } = useModal();
  const fetchUser = useUserStore((state) => state.fetchUser);
  const userId = useUserStore((state) => state.user?.id);
  const handleCancel = () => {
    createQuestion((res, rej) => ({
      title: "Are you sure you want to cancel this subscription?",
      body: `If you cancel this subscription, your services will continue until ${dateFormat(
        new Date(nextBillingDate),
        "ddd dd mmmm yyyy",
      )}. To resume, create a new subscription.`,
      actions: [
        {
          key: "back",
          buttonStyle: "primary",
          color: "fathomiser",
          children: "Back",
          className: "justify-self-start",
          onClick: () => {
            res();
            clearModal();
          },
        },
        {
          key: "cancel",
          buttonStyle: "secondary",
          color: "red",
          className: "justify-self-end",
          children: "Cancel Subscription",
          onClick: () =>
            cancelSubscription(id).then(async () => {
              userId && (await fetchUser({ id: userId }));
              res();
              clearModal();
            }),
        },
      ],
    }));
  };

  const encodedSensorId = encodeSensorId(imei);
  return (
    <div className="border rounded-md bg-white border-gray-300 p-3">
      <div>
        <Heading size={4} className="text-gray-600">
          {tankName ? tankName : `Sensor ID: ${encodedSensorId}`}
        </Heading>
        {tankName && (
          <Subheading className="mt-1">Sensor ID: {encodedSensorId}</Subheading>
        )}
      </div>
      <SectionBreak className="my-2 md:my-4" />
      <div className="flex flex-col gap-4 md:flex-row justify-between md:items-center">
        <div>
          <Heading size={4} className="text-gray-600">
            NZD $
            {(
              Math.round(billingAmount * Math.pow(10, 2)) / Math.pow(10, 2)
            ).toFixed(2)}{" "}
            / {humanizeDuration(billingFrequency * 1000)}
          </Heading>
          <Subheading className="mt-1">
            {nextBillingDate ? (
              <>
                Next billing{" "}
                {dateFormat(new Date(nextBillingDate), "ddd dd mmmm yyyy")}
              </>
            ) : (
              "Cancelled"
            )}
          </Subheading>
        </div>
        {nextBillingDate && (
          <Button
            buttonStyle="secondary"
            color="red"
            onClick={handleCancel}
            className="w-full md:w-auto px-2 py-1"
          >
            Cancel Subscription
          </Button>
        )}
      </div>
    </div>
  );
};
