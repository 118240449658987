import { InputWrapper, Button } from "@components/form";
import { useModal } from "@hooks/useModal";
import { useHistory } from "react-router-dom";
import { useTanksStore } from "../../../../state";

export const DeleteTankForm = ({ id }: { id: string }) => {
  const deleteTank = useTanksStore((state) => state.deleteTank);
  const { createQuestion, clearModal } = useModal();
  const history = useHistory();

  const handleDelete = () => {
    createQuestion((res, rej) => ({
      title: "Are you sure you want to delete this tank?",
      body: "Deleting this tank is irreversible. Deleted tanks can not be recovered.",
      actions: [
        {
          color: "gray",
          children: "Cancel",
          buttonStyle: "secondary",
          key: "cancel",
          onClick: () => {
            clearModal();
            res();
          },
        },
        {
          color: "red",
          children: "Delete",
          buttonStyle: "primary",
          key: "delete",
          onClick: () => {
            deleteTank({ id }).then(() => {
              res();
              clearModal();
              history.push("/home");
            });
          },
        },
      ],
    }));
  };

  return (
    <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-6">
      <InputWrapper title="Delete Tank">
        <span>
          <Button
            className="px-4 py-1"
            buttonStyle="secondary"
            color="red"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </span>
        <span>
          <Button
            className="px-4 py-1"
            buttonStyle="secondary"
            color="red"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </span>
      </InputWrapper>
    </div>
  );
};
