import { useFormik } from "formik";
import { useState } from "react";
import { AnySchema, InferType } from "yup";

export const useFormSection = <T extends AnySchema>({
  schema,
  defaultValues,
  onSubmit,
  defaultEditing = false,
}: {
  schema: T;
  defaultValues: Partial<InferType<T>>;
  onSubmit: (newValues: InferType<T>) => Promise<void>;
  defaultEditing?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(defaultEditing);
  const form = useFormik<InferType<T>>({
    initialValues: defaultValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      console.log({ values });
      setIsLoading(true);
      await onSubmit(values).catch((e) => {
        return form.resetForm();
      });
      setIsLoading(false);
      setIsEditing(false);
    },
  });

  const getBasicFieldProps = (id: string) => ({
    id,
    value: form.values[id] ?? "",
    error: !!((form.touched[id] || form.submitCount >= 1) && form.errors[id]),
    onChange: form.handleChange,
    onBlur: form.handleBlur,
  });

  const getInputWrapperProps = (id: string) => ({
    errorMessage:
      ((form.touched[id] || form.submitCount > 0) && form.errors[id]) ||
      undefined,
    isEditing,
  });

  return {
    ...form,
    isLoading,
    isEditing,
    setIsLoading,
    setIsEditing,
    getBasicFieldProps,
    getInputWrapperProps,
  };
};
