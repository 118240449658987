import { ReactNode, useEffect, useMemo } from "react";
import { App } from "@capacitor/app";
import { useTanksStore } from "../tanks.store";
import { useNotificationRulesStore } from "../notificationRules.store";
import { useHistory } from "react-router-dom";

export type TanksProviderProps = { children: ReactNode };

export const TanksProvider = ({ children }: TanksProviderProps) => {
  const history = useHistory();
  useEffect(() => {
    useTanksStore.getState().fetchTanks();
  }, []);

  useEffect(() => {
    const listener = App.addListener("resume", () => {
      useTanksStore.getState().fetchTankOverviews();
    });
    return () => {
      listener.then((x) => x.remove());
    };
  }, []);

  useEffect(() => {
    return useTanksStore.subscribe(async (state, prevState) => {
      const firstTankId = Object.values(state.tanks || {})[0]?.id;
      if (!state.selectedTankId && state.tanks && firstTankId) {
        state.selectTank({ id: firstTankId });
        history.push("/home");
      }

      if (state.tanks) {
        const tanksArr = Object.values(state.tanks);
        const prevTanksArr = Object.values(prevState.tanks || {});
        const isTanksEqual =
          tanksArr.length === prevTanksArr.length &&
          tanksArr.every((e, index) => e === prevTanksArr[index]);
        if (!isTanksEqual) {
          state.fetchTankOverviews();
        }
      }
      if (
        state.selectedTankId !== prevState.selectedTankId &&
        state.selectedTankId &&
        state.tanks?.[state.selectedTankId]
      ) {
        useNotificationRulesStore
          .getState()
          .fetchNotificationRules({ tankId: state.selectedTankId });
      }
    });
  }, []);

  return useMemo(() => <>{children}</>, [children]);
};
