import { ReactNode, useEffect, useMemo } from "react";
import { App } from "@capacitor/app";
import { useNotificationsStore } from "../notifications.store";

export type NotificationsProviderProps = { children: ReactNode };

export const NotificationsProvider = ({
  children,
}: NotificationsProviderProps) => {
  useEffect(() => {
    useNotificationsStore.getState().fetchNotifications({});
  }, []);

  useEffect(() => {
    const listener = App.addListener("resume", () => {
      useNotificationsStore.getState().fetchNotifications({});
    });
    return () => {
      listener.then((x) => x.remove());
    };
  }, []);

  return useMemo(() => <>{children}</>, [children]);
};
