import dateFormat from "dateformat";
import { HiChevronLeft } from "react-icons/hi";
import { Heading, Subheading } from "@components/typography";
import { useSelectedTank } from "@hooks/useSelectedTank";

const formatEllipsis = (str?: string) => str?.substring(0, 30) + "...";

export const HomeHeading = ({ onBack }: { onBack: () => void }) => {
  const { selectedTank, overview } = useSelectedTank();

  const selectedTankNameLength = selectedTank?.name?.length ?? 0;
  const shouldEllipsis = selectedTankNameLength > 30;

  console.log();

  return (
    <div className="grid grid-cols-[1fr_4fr_1fr] whitespace-nowrap items-center sm:flex flex-row sm:items-start w-full border-gray-300 border-b sm:border-b-0 p-2 sm:px-4">
      <HiChevronLeft
        className="w-8 h-8 text-gray-500 sm:hidden focus:outline-none"
        onClick={onBack}
        onKeyDown={(e) => e.code === "Space" && onBack()}
        tabIndex={0}
        role="button"
      />

      <span className="flex flex-col items-center sm:items-start justify-start">
        <Heading size={2} className="text-gray-700">
          {shouldEllipsis
            ? formatEllipsis(selectedTank?.name)
            : selectedTank?.name}
        </Heading>
        {overview?.lastActive ? (
          <Subheading>
            Last updated:{" "}
            {dateFormat(new Date(overview?.lastActive), "h:MMtt dS mmmm yyyy")}
          </Subheading>
        ) : (
          <Subheading>Last updated: never</Subheading>
        )}
      </span>
    </div>
  );
};
