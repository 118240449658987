import { useSelectedTank } from "@hooks/useSelectedTank";
import LoadingSpinner from "@components/LoadingSpinner";
import { useTanksStore } from "../../state";
import { TankSelectorOption } from "./TankSelectorOption";
import { useHistory } from "react-router-dom";

export type TankSelectorProps = {
  onClick?: () => void;
};
export const TankSelector = ({ onClick }: TankSelectorProps) => {
  const tanks = useTanksStore((state) => state.tanks);
  const { selectedTank } = useSelectedTank();
  const history = useHistory();

  // TODO: Find a better place for this
  // if (tanks && Object.values(tanks).length === 0)
  //   return <Redirect to="/new-tank" />;

  return (
    <div className="flex flex-col p-4 gap-y-2 overflow-y-scroll h-full">
      {tanks ? (
        Object.values(tanks)
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((tank) => (
            <TankSelectorOption
              key={tank.id}
              tank={tank}
              selected={tank.id === selectedTank?.id}
              onClick={() => {
                onClick && onClick();
                history.push(`/home/${tank.id}`);
              }}
            />
          ))
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};
